/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable no-undef */

import {listFunctionsPage, getOneEndpoint, listDatabases} from 'api-lofty';
import {exhaustiveSearchReadDB, searchForReadDB} from '../../util/searchParentComponent';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  async function getFunctionPagesData() {
    const token = opts.token;
    const idProject = opts.idProject;
    const idPage = opts.idPage;
    const resData = await listFunctionsPage({token, idPage, idProject});
    return resData.data;
  }

  function listAllArrayVariables(model) {
    const varData = [];
    const localVars = opts.localVars;
    const globalVars = opts.globalVars;
    localVars.filter((index) => (index.type === 'arrayDatabase' || index.type === 'cartArray'))
      .forEach((variable) => {
        varData.push({name: `${variable.label}--local`, value: variable._id, originId: variable.idDatabase});
      });
    globalVars.filter((index) => (index.typeValue === 'arrayDatabase' || index.typeValue === 'cartArray'))
      .forEach((variable) => {
        varData.push({name: `${variable.label}--global`, value: variable._id, originId: variable.idDatabase});
      });
    const traitAddition = {
      type: 'select',
      name: 'arrayOptionState',
      label: 'Estado de Tipo Lista',
      changeProp: 1,
      options: varData,
      default: model?.attributes?.arrayOptionState,
    };
    model.addTrait(traitAddition);
  }

  function listOneDataVariables(model) {
    const varData = [];
    const localVars = opts.localVars;
    const globalVars = opts.globalVars;
    localVars.filter((index) => (index.type === 'database'))
      .forEach((variable) => {
        varData.push({name: `${variable.label}--local`, value: variable._id, originId: variable.idDatabase});
      });
    globalVars.filter((index) => (index.typeValue === 'database'))
      .forEach((variable) => {
        varData.push({name: `${variable.label}--global`, value: variable._id, originId: variable.idDatabase});
      });
    const traitAddition = {
      type: 'select',
      name: 'arrayOptionState',
      label: 'Estado de Dato',
      changeProp: 1,
      options: varData,
      default: model?.attributes?.arrayOptionState,
    };
    model.addTrait(traitAddition);
  }

  async function getDatabaseSelections(model) {
    try {
      const optionsSearch = model.getTrait('arrayOptionState').attributes?.options;
      const selectedOption = optionsSearch.find(
        (index) => (index.value === model.attributes.arrayOptionState),
      );
      const allReadDB = exhaustiveSearchReadDB(model, []);
      const token = opts.token;
      const idProject = opts.idProject;
      const resData = await listDatabases({token, idProject});
      const selectOptions = [];
      for (const itemsReader of allReadDB) {
        const getEndpointData = await getOneEndpoint({token, _id: itemsReader.idEndpoint});
        const dbSelected = resData.data.find(
          (index) => (index._id === getEndpointData.data.idDatabase),
        );
        if (getEndpointData.data.typeFunction.includes('custom') && getEndpointData.data?.responseDatabase === selectedOption.originId) {
          const exists = selectOptions.find(
            (endpoint) => endpoint.value === getEndpointData.data?._id,
          );
          if (!exists) {
            selectOptions.push(
              {
                value: getEndpointData.data?._id,
                name: `${dbSelected.label} -- ${getEndpointData.data.typeFunction}`,
              },
            );
          }
        } else if (getEndpointData.data?.idDatabase === selectedOption.originId) {
          const exists = selectOptions.find(
            (endpoint) => endpoint.value === getEndpointData.data?._id,
          );
          if (!exists) {
            selectOptions.push(
              {
                value: getEndpointData.data?._id,
                name: `${dbSelected.label} -- ${getEndpointData.data.typeFunction}`,
              },
            );
          }
        }
      }

      if (selectOptions.length <= 0) {
        // eslint-disable-next-line no-alert
        alert('La variable no tiene relacion con las lecturas de coleccion encontrada');
      } else {
        const traitAddition = {
          type: 'select',
          name: 'toAddListFromReadDB',
          label: 'Lectura de Coleccion',
          changeProp: 1,
          options: selectOptions,
          default: model?.attributes?.toAddListFromReadDB,
        };
        model.addTrait(traitAddition);
      }
    } catch (error) {
      //
    }
  }

  async function restoreTraitData(model) {
    try {
      const functionList = await getFunctionPagesData();
      /**
        * DUMMY DATA
        const values = [
          {
            value: 'sampleval',
            name: 'samps',
            content: {slug: 'sampleval', class: 'sas', designation: 'marksman'},
          },
          {
            value: 'samplebeta',
            name: 'beta',
            content: {slug: 'samplebeta', class: 'seal', designation: 'engineer'},
          },
        ];
      */
      const values = [];
      if (functionList.length > 0) {
      // eslint-disable-next-line array-callback-return
        functionList.map((index) => {
          values.push({value: index._id, name: index.label, content: index});
        });
        const traitAddition = {
          type: 'select',
          name: 'customFunctionPlugin',
          label: 'Funcion',
          changeProp: 1,
          options: values,
          default: model.attributes.customFunctionPlugin,
        };
        model.addTrait(traitAddition);
      }
    } catch (error) {
      //
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value === 'standardButton') {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateButtonState());
        this.listenTo(model, 'change:buttonUtility', this.useCustomFunctions);
        this.listenTo(model, 'change:customFunctionPlugin', this.selectCustomFunction);
        this.listenTo(model, 'change:arrayOptionState', this.selectOriginDatabase);
      },
      async updateButtonState() {
        try {
          if (this.model.attributes.sluglofty.includes('standardButton')) {
            // console.log(this.model);
            const optionsData = [
              {name: 'Valor por defecto', value: 'default'},
              {name: 'Funcion', value: 'function'},
            ];
            if (searchForReadDB(this.model)) {
              optionsData.push({name: 'Agregar item de Listado', value: 'addlist'});
              optionsData.push({name: 'Agregar a dato', value: 'setData'});
            }
            this.model.removeTrait('buttonUtility');
            const defaultValue = this.model.attributes.buttonUtility || 'default';
            const traitPagesWithParams = {
              type: 'select',
              name: 'buttonUtility',
              label: 'Utilidad',
              changeProp: 1,
              options: optionsData,
              default: defaultValue,
            };
            this.model.addTrait(traitPagesWithParams);
            if (this.model.attributes.customFunctionPlugin) {
              restoreTraitData(this.model);
            }
            if (this.model.attributes.buttonUtility === 'addlist') {
              listAllArrayVariables(this.model);
            }
            if (this.model.attributes.buttonUtility === 'setData') {
              listOneDataVariables(this.model);
            }
            if (this.model.attributes.arrayOptionState) {
              await getDatabaseSelections(this.model);
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
      async useCustomFunctions() {
        try {
          if (this.model.attributes.sluglofty === 'standardButton') {
            const buttonUtilitySelection = this.model.getTrait('buttonUtility');
            const traitValue = buttonUtilitySelection.attributes.value;
            if (traitValue === 'function') {
              this.model.removeTrait('arrayOptionState');
              this.model.removeTrait('toAddListFromReadDB');
              const functionList = await getFunctionPagesData();
              const values = [];
              if (functionList.length > 0) {
              // eslint-disable-next-line array-callback-return
                functionList.map((index) => {
                  values.push({value: index._id, name: index.label, content: index});
                });
                const traitAddition = {
                  type: 'select',
                  name: 'customFunctionPlugin',
                  label: 'Funcion',
                  changeProp: 1,
                  options: values,
                };
                this.model.addTrait(traitAddition);
              }
            } else if (traitValue === 'addlist') {
              this.model.removeTrait('customFunctionPlugin');
              this.model.removeTrait('toAddListFromReadDB');
              listAllArrayVariables(this.model);
            } else if (traitValue === 'setData') {
              this.model.removeTrait('customFunctionPlugin');
              this.model.removeTrait('toAddListFromReadDB');
              listOneDataVariables(this.model);
            } else {
              this.model.removeTrait('customFunctionPlugin');
              this.model.removeTrait('arrayOptionState');
              this.model.removeTrait('toAddListFromReadDB');
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
      selectCustomFunction() {
        try {
          if (this.model.attributes.sluglofty === 'standardButton') {
            const functionSelection = this.model.getTrait('customFunctionPlugin');
            const availableOptions = functionSelection.attributes.options;
            const selectedValue = functionSelection.attributes.value;
            const getSelectedOption = availableOptions.find((index) => (
              index.value === selectedValue
            ));
            this.model.addAttributes({customPageFunction: getSelectedOption.content});
          }
        } catch (error) {
          //
        }
      },
      async selectOriginDatabase() {
        try {
          if (this.model.attributes.sluglofty === 'standardButton') {
            await getDatabaseSelections(this.model);
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
