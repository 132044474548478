/* eslint-disable no-await-in-loop */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */
import {
  listPage,
} from 'api-lofty';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  async function getPagesData() {
    const token = opts.token;
    const idProject = opts.idProject;
    const resData = await listPage({token, idProject});
    const noParamsPage = resData.data.filter((index) => (!index.url.match(/:|\*/)));
    const retVal = noParamsPage.map((index) => ({value: index.url, name: index.url}));
    return retVal;
  }

  async function restoreResponseData(model) {
    try {
      if (model.attributes.actionRegister) {
        const traitActionRegister = {
          type: 'select',
          name: 'actionRegister',
          label: 'Accion',
          changeProp: 1,
          options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
          default: model.attributes.actionRegister,
        };
        model.addTrait(traitActionRegister);
        if (model.attributes.actionRegister === 'message') {
          const alertMessage = {
            type: 'text',
            name: 'alertMessage',
            label: 'Mensaje',
            changeProp: 1,
          };
          model.addTrait(alertMessage);
        } else if (model.attributes.actionRegister === 'redirect') {
          const options = await getPagesData();
          const hrefRedirect = {
            type: 'select',
            name: 'redirectPage',
            label: 'Redireccionar',
            changeProp: 1,
            options,
            default: model.attributes.redirectPage,
          };
          model.addTrait(hrefRedirect);
        }
      } else {
        const traitActionRegister = {
          type: 'select',
          name: 'actionRegister',
          label: 'Accion',
          changeProp: 1,
          options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
          default: '',
        };
        model.addTrait(traitActionRegister);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (
          attr['data-gjs-sluglofty']
          && (
            attr['data-gjs-sluglofty'].value.includes('loginWithGoogleButton')
          )
        ) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateComponent());
        this.listenTo(model, 'change:actionRegister', this.actionForRegister);
      },
      async updateComponent() {
        if (this.model.attributes.sluglofty === 'loginWithGoogleButton') {
          restoreResponseData(this.model);
        }
        if (this.model.attributes.sluglofty === 'AuthenticationUserContainer') {
          const traitActionRegister = {
            type: 'select',
            name: 'actionRegister',
            label: 'Accion',
            changeProp: 1,
            options: [{value: 'redirect', name: 'Redireccionamiento'}, {value: 'message', name: 'Mensaje'}],
            default: '',
          };
          this.model.addTrait(traitActionRegister);
        }
      },
      async actionForRegister() {
        try {
          const traitSelection = this.model.getTrait('actionRegister').attributes.value;
          if (traitSelection === 'message') {
            this.model.removeTrait('redirectPage');
            const alertMessage = {
              type: 'text',
              name: 'alertMessage',
              label: 'Mensaje',
              changeProp: 1,
            };
            this.model.addTrait(alertMessage);
          } else if (traitSelection === 'redirect') {
            this.model.removeTrait('alertMessage');
            const options = await getPagesData();
            const hrefRedirect = {
              type: 'select',
              name: 'redirectPage',
              label: 'Redireccionar',
              changeProp: 1,
              options,
            };
            this.model.addTrait(hrefRedirect);
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
