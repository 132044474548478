export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
    <svg version="1.1" id="Capa_1" class="icon-lofty-component-block" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 24 24" style="enable-background:new 0 0 600 600;" xml:space="preserve">
      <path d="M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M17 11.5V13H11V7H12.5V11.5H17Z" />
    </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.label}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Componente para llevar una conteo regresivo.', id: 'component-countdown-lofty-editor'},
    content: `
      <span data-js="countdown" data-gjs-sluglofty="countdownComponent" style="display: inline-block; padding: 10px;">
            <div
              style="display: inline-block; margin: 0 10px;padding: 10px;"
              data-gjs-sluglofty="countdownSection"
            >
              <div data-js="countdown-day" data-gjs-sluglofty="countdownDay" style="font-size: 2rem;"></div>
              <div data-gjs-sluglofty="countdownLabel" style="font-size: 2rem;">${opts.labelDays}</div>
            </div>
            <div
              style="display: inline-block; margin: 0 10px;padding: 10px;"
              data-gjs-sluglofty="countdownSection"
            >
              <div data-js="countdown-hour" data-gjs-sluglofty="countdownHour" style="font-size: 2rem;"></div>
              <div data-gjs-sluglofty="countdownLabel" style="font-size: 2rem;">${opts.labelHours}</div>
            </div>
            <div
              style="display: inline-block; margin: 0 10px;padding: 10px;"
              data-gjs-sluglofty="countdownSection"
            >
              <div data-js="countdown-minute" data-gjs-sluglofty="countdownMinute" style="font-size: 2rem;"></div>
              <div data-gjs-sluglofty="countdownLabel" style="font-size: 2rem;">${opts.labelMinutes}</div>
            </div>
            <div
              style="display: inline-block; margin: 0 10px;padding: 10px;"
              data-gjs-sluglofty="countdownSection"
            >
              <div data-js="countdown-second" data-gjs-sluglofty="countdownSeconds" style="font-size: 2rem;"></div>
              <div data-gjs-sluglofty="countdownLabel" style="font-size: 2rem;">${opts.labelSeconds}</div>
            </div>
          </span>
          <span data-js="countdown-endtext" data-gjs-sluglofty="countdownEndtext" style="font-size: 2rem;"></span>
    ${style}`,
  });
};
