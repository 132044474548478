/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  function handleView(model) {
    const componentData = model.get('components');
    const toHide = componentData.filter((index) => (index.attributes.sluglofty === 'dropdownMenuContent'));
    toHide.forEach((index) => {
      const styleData = index.getStyle();
      if (!styleData.display) {
        index.setStyle({display: 'none'});
      } else if (styleData.display === 'none') {
        index.setAttributes({style: 'display: block;'});
      } else {
        index.setAttributes({style: 'display: none'});
      }
    });
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('dropdownMenuContainer')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        // console.log(error);
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, 'change:paramPdfSelection', this.addVariableGlobalToComponent);
        this.listenTo(model, '', this.updateComponent());
      },
      updateComponent() {
        if (this.model.attributes.sluglofty.includes('dropdownMenuContainer')) {
          this.model.removeTrait('showHideMenu');
          const fieldsMenuManager = {
            name: 'showHideMenu',
            type: 'button',
            text: 'Visualizar/Ocultar Menu',
            command: () => handleView(this.model),
          };
          this.model.addTrait(fieldsMenuManager);
        }
      },
    }),
  });
};
