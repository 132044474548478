/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-undef */
import React, {useState} from 'react';
import {
  Drawer, Box, Button,
  Tabs, Tab, AppBar,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import 'toastr/build/toastr.min.css';
import TableVariables from './list/TableVariables';
import ModalAddVariable from './Modal/ModalAddVariable';

const useStyle = makeStyles(() => ({
  mainBoxContainer: {
    width: '450px',
    padding: '10px',
    backgroundColor: '#242424',
    height: '100%',
  },
  boxAreaSection: {
    width: '100%',
    // backgroundColor: '#F2F2F2',
    border: '1px solid #BDBDBD',
  },
  boxTitleArea: {
    padding: '20px',
  },
  titleDivisor: {
    height: '1px',
    width: '100%',
    background: '#14569E',
    marginTop: '10px',
    marginBottom: '10px',
  },
  mainTitleStyle: {
    fontSize: '19px',
    fontWeight: 'bold',
    textAlign: 'center',
    // color: '#292151',
    color: '#fff',
  },
  boxVariableContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  titleListStyle: {
    fontSize: '16px',
    color: '#9B9B9B',
    marginTop: '15px',
    marginBottom: '10px',
    fontWeight: '500',
    textAlign: 'center',
  },
  boxOptionsContainer: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  buttonOptionStyle: {
    width: '100%',
    marginRight: '5px',
    color: 'rgba(32, 21, 73, 1)',
    fontWeight: 'bold',
    fontSize: '19px',
    backgroundColor: '#fff',
    border: '2px solid',
    borderColor: 'rgba(32, 21, 73, 1)',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    textTransform: 'none',
  },
}));

function DrawerVariable({
  open, handleClose, localVariables, globalVariables,
  addNewVariableHandler, deleteVariable, token, idProject,
}) {
  const classes = useStyle();
  const [openDialog, setOpenDialog] = useState(false);
  const [typeAddition, setTypeAddition] = useState('');
  const [tabSelect, setTabSelect] = React.useState(0);

  function handleOpenDialog(type) {
    setTypeAddition(type);
    setOpenDialog(true);
  }

  return (
    <Drawer
      open={open}
      onClose={() => handleClose()}
      anchor="right"
    >
      <Box component="div" className={classes.mainBoxContainer}>
        <Box component="div" className={classes.boxAreaSection}>
          <Box component="div" className={classes.boxTitleArea}>
            <Box component="div" className={classes.mainTitleStyle}>
              Variables
            </Box>
            <Box component="div" className={classes.titleDivisor} />
          </Box>
          <AppBar position="static">
            <Tabs
              value={tabSelect}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#29B2EF',
                },
              }}
            >
              <Tab label="Local" onClick={() => setTabSelect(0)} style={{width: '50%', fontWeight: 600}} />
              <Tab label="Global" onClick={() => setTabSelect(1)} style={{width: '50%', fontWeight: 600}} />
            </Tabs>
          </AppBar>
          <Box style={{display: tabSelect === 0 ? 'block' : 'none'}}>
            {tabSelect === 0 && (
            <>
              <Box component="div" className={classes.boxOptionsContainer}>
                <Box component="div" className={classes.titleListStyle}>
                  Variables Locales
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.buttonOptionStyle}
                  onClick={() => handleOpenDialog('local')}
                >
                  Agregar Local
                </Button>
              </Box>
              <Box component="div" className={classes.boxVariableContainer}>
                <TableVariables data={localVariables} variableType="local" deleteVariable={(e) => deleteVariable(e)} />
              </Box>
            </>
            )}
          </Box>
          <Box style={{display: tabSelect === 1 ? 'block' : 'none'}}>
            <Box component="div" className={classes.boxOptionsContainer}>
              <Box component="div" className={classes.titleListStyle}>
                Variables Globales
              </Box>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.buttonOptionStyle}
                onClick={() => handleOpenDialog('global')}
              >
                Agregar Global
              </Button>
            </Box>
            <Box component="div" className={classes.boxVariableContainer}>
              <TableVariables data={globalVariables} variableType="global" deleteVariable={(e) => deleteVariable(e)} />
            </Box>
          </Box>
        </Box>
        <Box>
          <ModalAddVariable
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            type={typeAddition}
            handleCloseAddVariable={() => setOpenDialog(false)}
            addVariable={(e) => addNewVariableHandler(e)}
            token={token}
            idProject={idProject}
          />
        </Box>
      </Box>
    </Drawer>
  );
}

export default DrawerVariable;
