/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */
export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  // eslint-disable-next-line consistent-return

  function coundownScript(props) {
    const startfrom = props.startfrom;
    const endTxt = props.endText;
    // @ts-ignore
    const el = this;
    const countDownDate = new Date(startfrom).getTime();
    const countdownEl = el.querySelector('[data-js=countdown]');
    const endTextEl = el.querySelector('[data-js=countdown-endtext]');
    const dayEl = el.querySelector('[data-js=countdown-day]');
    const hourEl = el.querySelector('[data-js=countdown-hour]');
    const minuteEl = el.querySelector('[data-js=countdown-minute]');
    const secondEl = el.querySelector('[data-js=countdown-second]');
    const oldInterval = el.__gjsCountdownInterval;
    oldInterval && clearInterval(oldInterval);

    const connected = window.__gjsCountdownIntervals || [];
    const toClean = [];
    connected.forEach((item) => {
      if (!item.isConnected) {
        clearInterval(item.__gjsCountdownInterval);
        toClean.push(item);
      }
    });
    connected.indexOf(el) < 0 && connected.push(el);
    window.__gjsCountdownIntervals = connected.filter((item) => toClean.indexOf(item) < 0);

    function setTimer(days, hours, minutes, seconds) {
      dayEl.innerHTML = `${days < 10 ? `0 ${days}` : days}`;
      hourEl.innerHTML = `${hours < 10 ? `0 ${hours}` : hours}`;
      minuteEl.innerHTML = `${minutes < 10 ? `0 ${minutes}` : minutes}`;
      secondEl.innerHTML = `${seconds < 10 ? `0 ${seconds}` : seconds}`;
    }

    function moveTimer() {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const days = Math.floor(distance / 86400000);
      const hours = Math.floor((distance % 86400000) / 3600000);
      const minutes = Math.floor((distance % 3600000) / 60000);
      const seconds = Math.floor((distance % 60000) / 1000);

      setTimer(days, hours, minutes, seconds);

      if (distance < 0) {
        clearInterval(el.__gjsCountdownInterval);
        endTextEl.innerHTML = endTxt;
        countdownEl.style.display = 'none';
        endTextEl.style.display = '';
      }
    }

    if (countDownDate) {
      el.__gjsCountdownInterval = setInterval(moveTimer, 1000);
      endTextEl.style.display = 'none';
      countdownEl.style.display = '';
      moveTimer();
    } else {
      setTimer(0, 0, 0, 0);
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        script: coundownScript,
        'script-props': ['startfrom', 'endText'],
        droppable: false,
        traits: [{
          label: 'Limite',
          name: 'startfrom',
          changeProp: true,
          type: 'date',
        }, {
          label: 'Texto de Finalizado',
          name: 'endText',
          changeProp: true,
        }],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value === ('countdownComponent')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },
  });
};
