/* eslint-disable no-tabs */
export default (editor, opts) => {
  const c = opts;
  // const {blocks} = c;
  const bm = editor.BlockManager;
  bm.add(opts.name, {
    label: `
      <div style="display: flex; justify-content: center">
        <svg version="1.1" class="icon-lofty-component-block" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
      <g>
	<g>
		<path d="M316.526,106.415c-36.935,0-66.984,30.049-66.984,66.985c0,36.935,30.049,66.984,66.984,66.984
			c36.935,0,66.985-30.049,66.985-66.984C383.511,136.464,353.462,106.415,316.526,106.415z M316.526,219.987
			c-25.688,0-46.586-20.898-46.586-46.586c0-25.688,20.898-46.587,46.586-46.587c25.689,0,46.587,20.899,46.587,46.587
			C363.113,199.088,342.214,219.987,316.526,219.987z"/>
	</g>
</g>
<g>
	<g>
		<path d="M320.538,165.002v-13.104c5.834,0.449,7.539,3.321,10.501,3.321c3.949,0,5.565-4.936,5.565-7.36
			c0-6.193-10.501-7.628-16.066-7.809v-2.244c0-1.257-1.705-2.423-3.411-2.423c-1.975,0-3.411,1.167-3.411,2.423v2.513
			c-9.514,1.346-17.951,6.821-17.951,18.219c0,11.488,9.693,15.348,17.951,18.489v14.539c-6.642-1.167-9.693-6.462-13.463-6.462
			c-3.411,0-6.102,4.488-6.102,7.539c0,5.744,8.796,11.308,19.566,11.667v2.244c0,1.257,1.436,2.423,3.411,2.423
			c1.705,0,3.411-1.167,3.411-2.423v-2.603c10.501-1.705,17.681-8.436,17.681-19.566
			C338.219,172.272,328.705,168.053,320.538,165.002z M314.615,162.848c-3.411-1.436-5.744-3.052-5.744-5.654
			c0-2.154,1.705-4.218,5.744-5.026V162.848z M319.64,191.479v-12.027c3.231,1.526,5.475,3.411,5.475,6.372
			C325.116,189.055,322.693,190.761,319.64,191.479z"/>
	</g>
</g>
<g>
	<g>
		<path d="M501.801,59.038H131.252c-5.633,0-10.199,4.566-10.199,10.199v198.127H10.199C4.566,267.364,0,271.93,0,277.563v165.2
			c0,5.633,4.566,10.199,10.199,10.199h278.885c5.632,0,10.199-4.566,10.199-10.199v-155h202.517
			c5.632,0,10.199-4.566,10.199-10.199V69.237C512,63.604,507.433,59.038,501.801,59.038z M141.451,79.437h15.579l-7.789,7.79
			l-7.79,7.79V79.437z M141.451,251.786l15.578,15.578h-15.578V251.786z M278.885,432.564H20.398v-16.017h258.487V432.564z
			 M278.885,396.148H20.398V381.02h258.487V396.148z M278.885,287.762v72.86H20.398v-72.86L278.885,287.762L278.885,287.762z
			 M491.602,267.364h-15.578l15.578-15.578V267.364z M491.602,222.936l-44.428,44.428H185.878l-44.428-44.428v-99.073l44.428-44.428
			h261.295l44.428,44.428V222.936z M491.602,95.016l-15.579-15.579h15.579V95.016z"/>
	</g>
</g>
<g>
	<g>
		<path d="M468.751,145.728h-3.668c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199h3.668
			c5.632,0,10.199-4.566,10.199-10.199C478.951,150.294,474.383,145.728,468.751,145.728z"/>
	</g>
</g>
<g>
	<g>
		<path d="M435.15,145.728h-28.224c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.566,10.199,10.199,10.199h28.224
			c5.632,0,10.199-4.566,10.199-10.199C445.349,150.294,440.782,145.728,435.15,145.728z"/>
	</g>
</g>
<g>
	<g>
		<path d="M468.751,180.674h-61.826c-5.632,0-10.199,4.566-10.199,10.199c0,5.633,4.567,10.199,10.199,10.199h61.826
			c5.632,0,10.199-4.566,10.199-10.199C478.951,185.24,474.383,180.674,468.751,180.674z"/>
	</g>
</g>
<g>
	<g>
		<path d="M227.834,145.728h-61.827c-5.633,0-10.199,4.566-10.199,10.199c0,5.633,4.566,10.199,10.199,10.199h61.827
			c5.633,0,10.199-4.566,10.199-10.199C238.033,150.294,233.467,145.728,227.834,145.728z"/>
	</g>
</g>
<g>
	<g>
		<path d="M227.834,180.674h-61.827c-5.633,0-10.199,4.566-10.199,10.199c0,5.633,4.566,10.199,10.199,10.199h61.827
			c5.633,0,10.199-4.566,10.199-10.199C238.033,185.24,233.467,180.674,227.834,180.674z"/>
	</g>
</g>
<g>
	<g>
		<path d="M99.016,314.437H54.608c-5.633,0-10.199,4.566-10.199,10.199c0,5.633,4.566,10.199,10.199,10.199h44.408
			c5.633,0,10.199-4.566,10.199-10.199C109.215,319.004,104.649,314.437,99.016,314.437z"/>
	</g>
</g>
    </svg>
      </div>
      <div class="gjs-block-label">
        ${c.labelComponent}
      </div>`,
    category: c.category,
    attributes: {title: 'Carga informacion de compra. ', id: 'component-viewcartinfo-lofty-editor'},
    content: `
      <div
        data-gjs-slugLofty="viewCartInfoComponent"
        data-gjs-name="Visualizar informacion de Compra"
        style="padding: 10px"
        data-gjs-resizable="{bc: 1}"
      >
      </div
    `,
  });
};
