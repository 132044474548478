/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import React, {useState} from 'react';
import {
  Dialog, Box, TextField,
  Button, Typography, MenuItem,
} from '@material-ui/core';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import {createNewPdfParam} from 'api-lofty';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
    backgroundColor: '#242424',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#242424',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(243, 243, 243, 1)',
    margin: '10px',
    padding: '15px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '5px',
  },
  buttonAreaContainer: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    width: '100px',
    fontWeight: 'bold',
  },
}));

const ModalAddPdfParam = ({
  open, onClose, handleClose, handleAddUpdate,
  token, idProject, idPluginProject,
}) => {
  const [typeParam, setTypeParam] = useState('');
  const [nameParam, setNameParam] = useState('');
  const [slugParam, setSlugParam] = useState('');
  const typesOfFields = [
    {label: 'Texto', value: 'string'},
    {label: 'Numero', value: 'number'},
    {label: 'Usuario', value: 'user'},
    {label: 'Fecha', value: 'date'},
  ];
  const classes = useStyle();

  async function toAdd() {
    try {
      await createNewPdfParam({
        idPluginProject,
        idProject,
        token,
        label: nameParam,
        slug: slugParam,
        type: typeParam,
      });
      handleAddUpdate();
      toastr.success('Parametro Agregado');
      setTypeParam('');
      setNameParam('');
      setSlugParam('');
    } catch (error) {
      toastr.error(`${error?.info} || Error al Agregar Parametro`);
    }
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Parametros para PDF
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{backgroundColor: '#242424'}}>
        <Box component="div" style={{margin: '15px', padding: '5px'}}>
          <Box className={classes.messageContainer}>
            <Typography
              style={{
                position: 'relative',
                bottom: '10px',
              }}
            >
              Agregar Parametros del PDF
            </Typography>
          </Box>
          <Box>
            <div className="my-4">
              <TextField
                fullWidth
                name="label"
                type="text"
                label="Etiqueta"
                variant="standard"
                value={nameParam}
                onChange={(e) => setNameParam(e.target.value)}
                style={{backgroundColor: '#D9D9D9', color: '#fff', borderRadius: '5px'}}
              />
            </div>
            <div className="my-4">
              <TextField
                fullWidth
                name="name"
                type="text"
                label="Slug"
                variant="standard"
                value={slugParam}
                onChange={(e) => setSlugParam(e.target.value)}
                style={{backgroundColor: '#D9D9D9', color: '#fff', borderRadius: '5px'}}
              />
            </div>
            <div className="my-4">
              <TextField
                onChange={(e) => setTypeParam(e.target.value)}
                value={typeParam}
                select
                fullWidth
                name="type"
                type="text"
                label="Tipo"
                variant="standard"
                style={{backgroundColor: '#D9D9D9', color: '#fff', borderRadius: '5px'}}
              >
                {
                  typesOfFields.map(({label, value}) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))
                }
              </TextField>
            </div>
          </Box>
          <Box className={classes.buttonAreaContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => { if (handleClose) { handleClose(); } }}
              className={classes.buttonStyle}
            >
              Cerrar
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              onClick={(e) => toAdd(e)}
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAddPdfParam;
