import grapesjs from 'grapesjs';
import loadComponents from './components';
import loadBlocks from './blocks';

export default grapesjs.plugins.add('gjs-login-with-google', (editor, opts = {}) => {
  const exists = opts.userLogins?.find((item) => item.type === 'google');
  if (exists) {
    const options = {
      label: 'Sesion con Google',
      name: 'loginWithGoogle',
      category: 'Interacción de Usuario',
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const name in options) {
      if (!(name in opts)) opts[name] = options[name];
    }
    loadBlocks(editor, options);
    loadComponents(editor, opts);
  }
});
