import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gjs-countdown-component', (editor, opts = {}) => {
  const config = {
    blocks: [
      'countdown',
    ],
    name: 'countdown',
    stylePrefix: 'gjs-',
    label: 'Conteo',
    defText: 'Sample',
    category: 'Extra',
    labelDays: 'dias',
    labelHours: 'horas',
    labelMinutes: 'minutos',
    labelSeconds: 'segundos',
    ...opts,
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const name in config) {
    if (!(name in opts)) opts[name] = config[name];
  }
  loadBlocks(editor, config);
  loadComponents(editor, opts);
});
