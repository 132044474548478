/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
import {getPdfEditorParams} from 'api-lofty';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  function restoreComponent(component) {
    const componentData = component.get('components');
    const eliminateComponent = componentData.filter((index) => (index));
    eliminateComponent.map((index) => (index.collection.remove(index)));
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('paramComponent')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        // console.log(error);
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, 'change:paramPdfSelection', this.addVariableGlobalToComponent);
        this.listenTo(model, '', this.restoreData());
      },
      async addParamToComponent() {
        if (this.model.attributes.sluglofty.includes('paramComponent')) {
          restoreComponent(this.model);
          const getSelectedParam = this.model.getTrait('paramPdfSelection').attributes.value;
          const getOption = this.model.getTrait('paramPdfSelection').attributes.options;
          const filterData = getOption.find((obj) => (obj.value === getSelectedParam));
          const htmlAdder = `
            <div data-gjs-slugLofty="pdfParamValue" data-gjs-name="Valor de Parametro" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
              ${filterData.name}
            </div>
            `;
          this.model.append(htmlAdder);
        }
      },
      async restoreData() {
        if (this.model.attributes.sluglofty.includes('paramComponent')) {
          try {
            this.model.removeTrait('paramPdfSelection');
            const {idProject, idPluginProject, token} = opts;
            const getAllParams = await getPdfEditorParams({idPluginProject, idProject, token});
            const optionsData = getAllParams.data.map((index) => ({name: index.slug, value: index._id}));
            const traitData = {
              type: 'select',
              name: 'paramPdfSelection',
              label: 'Parametros',
              changeProp: 1,
              options: optionsData,
              default: this.model.attributes?.paramPdfSelection || '',
            };
            this.model.addTrait(traitData);
          } catch (error) {
            //
          }
        }
      },
    }),
  });
};
