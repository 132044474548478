import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Dialog, DialogContent, DialogTitle,
  TextField, MenuItem, Button,
} from '@material-ui/core';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  inputInfoContainer: {
    padding: '10px',
  },
  inputTitleInfo: {
    fontSize: '15px',
    fontWeight: 600,
    color: '#000',
  },
}));

export default function ModalCarouselCustomEndpointManager({
  data,
  params,
  variables,
  editTypeInput,
  editParamInput,
  open,
  onClose,
  numberOfCarousels,
  savedInfo,
}) {
  const classes = useStyle();
  const [inputs, setInputs] = useState([]);
  const [paramData, setParamData] = useState([]);
  const typesOfFields = [
    {value: 'variable', label: 'Variables'},
    {value: 'static', label: 'Estatico'},
    {value: 'useparams', label: 'Parametros'},
  ];
  useEffect(() => {
    setInputs(data);
    setParamData(params);
  });

  function checkTypeOf(paramValue, compareTo) {
    if (paramValue === 'string' && (compareTo === 'string' || compareTo === 'database' || compareTo === 'relation')) {
      return true;
    }
    if (paramValue === compareTo) {
      return true;
    }
    return false;
  }

  function retrieveDataValue(keyValue, type, param) {
    const findDataInSave = savedInfo?.find((index) => (
      index.keyIndex === keyValue && index.keyParam === param?.paramendpoint?.value));
    if (findDataInSave && type === 'state') {
      return findDataInSave.state;
    }
    if (findDataInSave && type === 'param') {
      return findDataInSave.value;
    }
    return '';
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Parametros de Personalizados
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          component="div"
          style={{
            margin: '15px', padding: '5px', height: '400px', overflowY: 'scroll',
          }}
        >
          {
            numberOfCarousels.map((value) => (
              <Box key={value._id} className={classes.inputInfoContainer}>
                {
              inputs?.map((index) => (
                <Box component="div" className={classes.inputInfoContainer} key={index}>
                  <Box component="div" className={classes.inputTitleInfo}>
                    {`Carousel: ${value._id}`}
                  </Box>
                  <Box component="div" className={classes.inputTitleInfo}>
                    {index.paramendpoint.label}
                  </Box>
                  <div className="my-4">
                    <TextField
                      onChange={(e) => editTypeInput(e.target.value, value._id, index)}
                      value={retrieveDataValue(value._id, 'state', index)}
                      select
                      fullWidth
                      name="type"
                      type="text"
                      label="Usar Parametro con:"
                      variant="standard"
                      disabled={index?.stringDefaultValues.length > 0}
                    >
                      {
                          typesOfFields.map(({label, value}) => (
                            <MenuItem value={value} key={value}>
                              {label}
                            </MenuItem>
                          ))
                        }
                    </TextField>
                  </div>
                  {retrieveDataValue(value._id, 'state', index) === 'useparams' && (
                    <div>
                      <TextField
                        select
                        fullWidth
                        name="type"
                        onChange={(e) => editParamInput(value._id, e.target.value, index)}
                        value={retrieveDataValue(value._id, 'param', index)}
                        type="text"
                        label="Parametros:"
                        variant="standard"
                      >
                        {
                          paramData?.map(({value, name}) => (
                            <MenuItem value={value} key={value}>
                              {name}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                  )}
                  {retrieveDataValue(value._id, 'state', index) === 'variable' && (
                    <div>
                      <TextField
                        select
                        fullWidth
                        name="type"
                        onChange={(e) => editParamInput(value._id, e.target.value, index)}
                        value={retrieveDataValue(value._id, 'param', index)}
                        type="text"
                        label="Variable:"
                        variant="standard"
                      >
                        {
                          variables?.filter(
                            (fieldData) => (checkTypeOf(index.paramendpoint.type, fieldData.type)),
                          )?.map(({value, name}) => (
                            <MenuItem value={value} key={value}>
                              {name}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                  )}
                  {retrieveDataValue(value._id, 'state', index) === 'static' && (!index?.paramendpoint?.stringDefaultValues || index?.paramendpoint?.stringDefaultValues?.length < 1) && (
                    <div>
                      <TextField
                        fullWidth
                        name="type"
                        onChange={(e) => editParamInput(value._id, e.target.value, index)}
                        value={retrieveDataValue(value._id, 'param', index)}
                        type="text"
                        label="Valor:"
                        variant="standard"
                      />
                    </div>
                  )}
                  {retrieveDataValue(value._id, 'state', index) === 'static' && (index?.paramendpoint?.stringDefaultValues.length > 0) && (
                    <div>
                      {/* <TextField
                        fullWidth
                        name="type"
                        onChange={(e) => editParamInput(value._id, e.target.value, index)}
                        value={retrieveDataValue(value._id, 'param', index)}
                        type="text"
                        label="Valor:"
                        variant="standard"
                      /> */}
                      <TextField
                        select
                        fullWidth
                        name="type"
                        onChange={(e) => editParamInput(value._id, e.target.value, index)}
                        value={retrieveDataValue(value._id, 'param', index)}
                        type="text"
                        label="Valor por defecto:"
                        variant="standard"
                      >
                        {
                          index?.paramendpoint?.stringDefaultValues?.map(({label, slug}) => (
                            <MenuItem value={slug} key={slug}>
                              {label}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                  )}
                </Box>
              ))
            }
              </Box>
            ))
          }
        </Box>
        <Box className={classes.buttonAreaContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            className={classes.buttonStyle}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
