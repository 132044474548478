import grapesjs from 'grapesjs';
import loadBlocks from './blocks';

export default grapesjs.plugins.add('gjs-stripe-form', (editor, opts = {}) => {
  const exists = opts.plugins?.find((item) => item.type === 'stripe');
  if (exists) {
    const config = {
      blocks: [
        'stripeForm',
      ],
      name: 'stripeForm',
      stylePrefix: 'gjs-',
      labelStripeForm: 'Formulario Stripe',
      defText: 'Sample',
      category: 'Pagos',
      ...opts,
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const name in config) {
      if (!(name in opts)) opts[name] = config[name];
    }
    loadBlocks(editor, config);
  }
});
