/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-undef */
import React, {useState} from 'react';
import {
  Drawer, Box, Button,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {deletePdfEditorParam} from 'api-lofty';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import TableParams from './list/TableParams';
import ModalAddPdfParam from './Modal/ModalAddPdfParam';

const useStyle = makeStyles(() => ({
  mainBoxContainer: {
    width: '450px',
    padding: '10px',
    backgroundColor: '#242424',
    height: '100%',
  },
  boxAreaSection: {
    width: '100%',
    // backgroundColor: '#F2F2F2',
    border: '1px solid #BDBDBD',
  },
  boxTitleArea: {
    padding: '20px',
  },
  titleDivisor: {
    height: '1px',
    width: '100%',
    // background: '#14569E',
    background: '#fff',
    marginTop: '10px',
    marginBottom: '10px',
  },
  mainTitleStyle: {
    fontSize: '19px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#fff',
  },
  boxVariableContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  titleListStyle: {
    fontSize: '16px',
    color: '#9B9B9B',
    marginTop: '15px',
    marginBottom: '10px',
    fontWeight: '500',
    textAlign: 'center',
  },
  boxOptionsContainer: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  buttonOptionStyle: {
    width: '100%',
    marginRight: '5px',
    color: 'rgba(32, 21, 73, 1)',
    fontWeight: 'bold',
    fontSize: '19px',
    backgroundColor: '#fff',
    border: '2px solid',
    borderColor: 'rgba(32, 21, 73, 1)',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    textTransform: 'none',
  },
}));

function DrawerParams({
  open, handleClose, pdfParams, handleUpdate,
  token, idProject, idPluginProject,
}) {
  const classes = useStyle();
  const [openDialog, setOpenDialog] = useState(false);

  function handleOpenDialog() {
    setOpenDialog(true);
  }

  async function handleDelete(e) {
    try {
      await deletePdfEditorParam({
        _id: e.idVariable,
        idPluginProject,
        idProject,
        token,
      });
      handleUpdate();
      toastr.success('Se ha eliminado el parametro');
    } catch (error) {
      toastr.error(`${error?.info || 'Error al eliminar parametro'}`);
    }
  }

  return (
    <Drawer
      open={open}
      onClose={() => handleClose()}
      anchor="right"
    >
      <Box component="div" className={classes.mainBoxContainer}>
        <Box component="div" className={classes.boxAreaSection}>
          <Box component="div" className={classes.boxTitleArea}>
            <Box component="div" className={classes.mainTitleStyle}>
              Parametros PDF
            </Box>
            <Box component="div" className={classes.titleDivisor} />
          </Box>
          <Box>
            <Box component="div" className={classes.boxOptionsContainer}>
              <Box component="div" className={classes.titleListStyle}>
                Parametros
              </Box>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.buttonOptionStyle}
                onClick={() => handleOpenDialog()}
              >
                Agregar Parametro
              </Button>
            </Box>
            <Box component="div" className={classes.boxVariableContainer}>
              <TableParams data={pdfParams} deleteVariable={(e) => handleDelete(e)} />
            </Box>
          </Box>
        </Box>
        <Box>
          <ModalAddPdfParam
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            handleClose={() => setOpenDialog(false)}
            token={token}
            idProject={idProject}
            idPluginProject={idPluginProject}
            handleAddUpdate={handleUpdate}
          />
        </Box>
      </Box>
    </Drawer>
  );
}

export default DrawerParams;
