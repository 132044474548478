import React, {useState, useEffect} from 'react';
import JoyRide from 'react-joyride';
import {getTutorialStatus, tutorialStatus} from 'api-lofty';
import {steps} from '../util/reactComponentsTutorial';
import geditorConfig from '../editorNormal/config';
import Sidebar from './EditorNormalComponent.js/Sidebar';
import TopNav from './EditorNormalComponent.js/TopNav';
import LogoImg from '../assets/navbar/logo_sidebar.png';
import ModalShowTutorial from './DialogShowTutorial';

function EditorNormal({
  idProject, idPage, token, url, openForm, paypalPlugin, openVariables, openLogic,
  localVars, globalVars, plugins, calendarPlugin, handleInputManager, handleDynamicLinkManager,
  handlePaymentMethodDynamic, handleCustomEndpointManager, handleOpenCarouselCustomEndpointManager,
  handleCustomGoogleIcons, handleViewCartModal, userLogins,
}) {
  // eslint-disable-next-line no-unused-vars
  const [editor, setEditor] = useState(null);
  // console.log(window.localStorage);
  const [openNewInEditor, setOpenNewInEditor] = useState(false);
  const [withTutorial, setWithTutorial] = useState(false);

  useEffect(() => {
    getTutorialStatus({token, typeTutorialUpdate: 'editor'}).then((res) => {
      if ((res.user.doneTutorialEditor === undefined)) {
        setOpenNewInEditor(true);
      }
      if (!res.user.doneTutorialEditor) {
        setOpenNewInEditor(true);
      }
    });
    const editorNew = geditorConfig({
      url,
      token,
      idProject,
      idPage,
      openForm,
      paypalPlugin,
      plugins,
      openVariables,
      openLogic,
      localVars,
      globalVars,
      calendarPlugin,
      handleInputManager,
      handleDynamicLinkManager,
      handlePaymentMethodDynamic,
      handleCustomEndpointManager,
      handleOpenCarouselCustomEndpointManager,
      handleCustomGoogleIcons,
      handleViewCartModal,
      userLogins,
    });
    setEditor(editorNew);
  }, [localVars, globalVars]);

  async function handleModalRes() {
    setOpenNewInEditor(false);
    setWithTutorial(true);
    await tutorialStatus({token, typeTutorialUpdate: 'editor'});
  }

  async function handleNoTutorial() {
    setOpenNewInEditor(false);
    await tutorialStatus({token, typeTutorialUpdate: 'editor'});
  }

  return (
    <div className="App">
      <ModalShowTutorial
        open={openNewInEditor}
        handleClose={() => setOpenNewInEditor(false)}
        handleOpenTutorial={() => handleModalRes()}
        handleWithoutTutorial={() => handleNoTutorial()}
      />
      <div>
        {withTutorial
          && (
            <JoyRide
              continuous
              hideCloseButton
              scrollToFirstStep
              // showProgress
              showSkipButton
              steps={steps}
              locale={{
                back: 'Anterior', close: 'Cerrar', last: 'Ultimo', next: 'Siguiente', open: 'Abrir dialogo', skip: 'Saltar',
              }}
              styles={{
                buttonNext: {
                  backgroundColor: 'rgba(41, 33, 81, 1)',
                },
                buttonBack: {
                  backgroundColor: 'rgba(41, 178, 239, 1)',
                  borderRadius: 4,
                  color: '#fff',
                },
              }}
            />
          )}
      </div>
      <div id="navbar" className="sidenav d-flex flex-column overflow-scroll">
        <nav className="navbar navbar-light">
          <div className="container-fluid" style={{display: 'flex', justifyContent: 'center'}} id="initial-Section-editor-lofty">
            {/*
              <p className="navbar-brand text-center w-100 mb-0 h3 logo">Lofty Apps</p>
            */}
            <img src={LogoImg} alt="Lofty Apps" width="200px" height="75px" />
          </div>
        </nav>
        <Sidebar />
      </div>
      <div className="main-content" id="main-content">
        <TopNav />
        <div id="editor" />
      </div>
    </div>
  );
}

export default EditorNormal;
