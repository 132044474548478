import React, {useEffect, useState} from 'react';
import {validTokenEditor, getPdfEditorParams} from 'api-lofty';
import {connect} from 'react-redux';
import Editor from '../components/EditorPdf';
import LoadingPage from './LoadingPage';
import {addToken} from '../actions/auth.actions';
import DrawerParams from '../components/DrawerParams';

function EditorPdfPage({
  token, addTokenApp, history, match,
}) {
  const [loading, setLoading] = useState(true);
  const [openDrawerParams, setOpenDrawerParams] = useState(false);
  const [paramData, setParamData] = useState([]);
  const {params} = match;
  const {idProject, idPluginProject} = params;

  async function handleParamData() {
    const resData = await getPdfEditorParams({idPluginProject, idProject, token});
    setParamData(resData.data);
  }

  useEffect(async () => {
    try {
      if (!token) {
        const params = new URLSearchParams(window.location.search);
        const tokenEditor = params.get('accesstoken');
        const resEditor = await validTokenEditor({tokenEditor});
        addTokenApp(resEditor);
        token = resEditor.token;
        handleParamData();
      }
    } catch (error) {
      // console.log(error);
      history.push('/error404');
    }
    setLoading(false);
  }, []);

  function openDrawerOfParams(state) {
    setOpenDrawerParams(state);
  }

  function callUpdate() {
    handleParamData();
  }

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <div>
      <Editor
        idProject={idProject}
        token={token}
        idPluginProject={idPluginProject}
        handleOpenDrawer={(e) => openDrawerOfParams(e)}
        _id=""
        url=""
      />
      <DrawerParams
        open={openDrawerParams}
        idProject={idProject}
        idPluginProject={idPluginProject}
        token={token}
        pdfParams={paramData}
        handleClose={() => setOpenDrawerParams(false)}
        handleUpdate={() => callUpdate()}
      />
    </div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addTokenApp: (doc) => dispatch(addToken(doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorPdfPage);
