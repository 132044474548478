/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */

import {verifyExistingComponent} from '../../util/searchForExistingComponent';
import {searchParentComponentForFields} from '../../util/searchParentComponent';
import {falseStateDivBoolean, trueStateDivBoolean} from './htmlComponents';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  function restoreComponent(component) {
    const componentData = component.get('components');
    const eliminateComponent = componentData.filter((index) => (index));
    eliminateComponent.map((index) => (index.collection.remove(index)));
  }

  function hideComponents(component, hide1, showComponent) {
    const componentData = component.get('components');
    const hideComponents = componentData.filter((index) => (index));
    hideComponents.forEach((index) => {
      if (index.attributes.sluglofty === hide1) {
        index.addAttributes({style: 'display: none'});
      }
      if (index.attributes.sluglofty === showComponent) {
        index.setAttributes({style: 'padding: 10px; min-height: 100px'});
      }
    });
  }

  function addTrait(model, type, answerValue) {
    if (type === 'variable') {
      const localVars = opts.localVars;
      const globarVars = opts.globalVars;
      const filteredVars = [];
      localVars.forEach((index) => {
        if (index.type === 'boolean') {
          filteredVars.push(index);
        }
      });
      globarVars.forEach((index) => {
        if (index.type === 'boolean') {
          filteredVars.push(index);
        }
      });
      const optionData = filteredVars.map((index) => ({name: index.label, value: index._id}));
      const traitVariables = {
        type: 'select',
        name: 'variables',
        label: 'Variables',
        changeProp: 1,
        options: optionData,
        default: answerValue || '',
      };
      model.addTrait(traitVariables);
    } else {
      // const variableData = opts.globalVars;
      // const optionData = variableData.map((index) => ({name: index.label, value: index._id}));
      const parentComponentVariables = searchParentComponentForFields(model);
      const traitFields = {
        type: 'select',
        name: 'fields',
        label: 'Campos',
        changeProp: 1,
        options: parentComponentVariables,
        default: answerValue || '',
      };
      model.addTrait(traitFields);
    }
    const traitRenderBooleanState = {
      type: 'select',
      label: 'Estado',
      name: 'stateBoolean',
      options: [{value: 'true', name: 'Verdadero'}, {value: 'false', name: 'Falso'}],
      default: 'true',
      changeProp: 1,
    };
    model.addTrait(traitRenderBooleanState);
  }

  function addTraitUserAuth(model) {
    const traitRenderBooleanState = {
      type: 'select',
      label: 'Estado',
      name: 'stateBoolean',
      options: [{value: 'true', name: 'Autenticado'}, {value: 'false', name: 'No Autenticado'}],
      default: 'true',
      changeProp: 1,
    };
    model.addTrait(traitRenderBooleanState);
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [{
          type: 'select', // Type of the trait
          label: 'Tipo de Verificacion', // The label you will see in Settings
          name: 'booleanVerifyType',
          options: [
            {value: 'database', name: 'Base De Datos'},
            {value: 'variable', name: 'Variables'},
            {value: 'userauth', name: 'Autenticacion'},
          ],
          changeProp: 1,
        }],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value.includes('booleanDynamicComponent')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        // console.log(error);
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, 'change:booleanVerifyType', this.configureVariableComponent);
        this.listenTo(model, 'change:variables', this.addVariables);
        this.listenTo(model, 'change:fields', this.addFieldToComponent);
        this.listenTo(model, 'change:stateBoolean', this.changeCurrentState);
        this.listenTo(model, '', this.restoreData());
      },
      /*
              -- Configure Type of Variable TRAIT --
        Configures the display of the modal inside the component
      */
      configureVariableComponent() {
        try {
          if (this.model.attributes.sluglofty.includes('booleanDynamicComponent')) {
            const getTypeVariable = this.model.getTrait('booleanVerifyType').attributes.value;
            if (getTypeVariable === 'variable') {
              restoreComponent(this.model);
              this.model.removeTrait('fields');
              addTrait(this.model, 'variable', '');
            } else if (getTypeVariable === 'database') {
              restoreComponent(this.model);
              this.model.removeTrait('variables');
              addTrait(this.model, 'database', '');
            } else {
              restoreComponent(this.model);
              this.model.removeTrait('variables');
              this.model.removeTrait('fields');
              const htmlAdder = trueStateDivBoolean('Autenticado');
              this.model.append(htmlAdder);
              addTraitUserAuth(this.model);
            }
          }
        } catch (error) {
          //
        }
      },
      addVariables() {
        if (this.model.attributes.sluglofty.includes('booleanDynamicComponent')) {
          restoreComponent(this.model);
          const getTypeVariable = this.model.getTrait('variables').attributes.value;
          const getOption = this.model.getTrait('variables').attributes.options;
          const filterData = getOption.filter((obj) => (obj.value === getTypeVariable));
          const htmlAdder = trueStateDivBoolean(filterData[0].name);
          this.model.append(htmlAdder);
        }
      },
      addFieldToComponent() {
        if (this.model.attributes.sluglofty.includes('booleanDynamicComponent')) {
          restoreComponent(this.model);
          const getField = this.model.getTrait('fields').attributes.value;
          const htmlAdder = trueStateDivBoolean(getField);
          this.model.append(htmlAdder);
        }
      },
      changeCurrentState() {
        try {
          if (this.model.attributes.sluglofty.includes('booleanDynamicComponent')) {
            const getStateBoolean = this.model.getTrait('stateBoolean').attributes.value;
            if (getStateBoolean === 'true') {
              hideComponents(this.model, 'booleanFalseDivDynamic', 'booleanTrueDivDynamic');
            } else {
              if (!verifyExistingComponent(this.model, 'booleanFalseDivDynamic')) {
                const htmlAdder = falseStateDivBoolean('Div Estado Falso');
                this.model.append(htmlAdder);
              }
              hideComponents(this.model, 'booleanTrueDivDynamic', 'booleanFalseDivDynamic');
            }
          }
        } catch (error) {
          //
        }
      },
      restoreData() {
        if (this.model.attributes.sluglofty.includes('booleanDynamicComponent')) {
          if (this.model.attributes.booleanVerifyType) {
            if (this.model.attributes.booleanVerifyType.includes('variable')) {
              addTrait(this.model, 'variable', this.model.attributes.variables);
            } else if (this.model.attributes.booleanVerifyType.includes('userauth')) {
              addTraitUserAuth(this.model);
            } else {
              addTrait(this.model, 'fields', this.model.attributes.fields);
            }
          }
        }
      },
    }),
  });
};
