import React, {useEffect, useState} from 'react';
import {
  listFieldsDatabase,
  listMethodPaymentProject, listParamsEndpoint,
  listEndpointPayment,
} from 'api-lofty';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Dialog, DialogContent, DialogTitle,
  TextField, MenuItem, Button,
} from '@material-ui/core';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import {databaseFieldsGetComponent} from '../../util/databaseFieldsComponent';
import {restoreComponentToDefault} from '../../util/grapeJsCanvasFunctions';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#242424',
    padding: '10px',
    color: '#fff',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  inputInfoContainer: {
    padding: '10px',
  },
  inputTitleInfo: {
    fontSize: '15px',
    fontWeight: 600,
    color: '#000',
  },
}));

export default function ModalPayInfo({
  data, open, onClose, token, idProject, variables, pageParams,
}) {
  const classes = useStyle();
  const [paymentMethodData, setPaymentMethodData] = useState([]);
  const [endpointData, setEndpointData] = useState([]);
  const [paramData, setParamData] = useState(data?.attributes?.attributes?.viewPayInfoParams || []);

  async function handlePaymentMethods() {
    try {
      const getListPaymentMethods = await listMethodPaymentProject({token, project: idProject});
      setPaymentMethodData(getListPaymentMethods.data);
      // getListPaymentMethods.data.find((index) => (index.type || index.idDatabase))
    } catch (error) {
      //
    }
  }

  async function handleEndpointList(value) {
    try {
      const getSelectedPayment = paymentMethodData.find((index) => (index._id === value));
      const getListEndpoints = await listEndpointPayment({
        token,
        idProject,
      });
      const limitToSelectedPayMethod = getListEndpoints?.data?.filter(
        (index) => (index.idMethodPayment === value),
      );
      data.addAttributes({paymentSelection: getSelectedPayment});
      setEndpointData(limitToSelectedPayMethod);
      toastr.success('Actualizado');
    } catch (error) {
      toastr.error('Error al actualizar informacion');
    }
  }

  async function handleParamData(value) {
    try {
      const getSelectedEndpoint = endpointData.find((index) => (index._id === value));
      const getSelectedPayment = data?.attributes?.attributes?.paymentSelection;
      data.addAttributes({endpointSelection: getSelectedEndpoint});
      const getParamData = await listParamsEndpoint({
        idEndpoint: getSelectedEndpoint._id,
        idProject,
        token,
      });
      restoreComponentToDefault(data);
      if (getSelectedPayment?.type === 'shoppingcart' || getSelectedPayment?.type === 'service') {
        const listFields = await listFieldsDatabase({
          idDatabase: getSelectedPayment.idDatabase, idProject, token,
        });
        const resFieldsReact = databaseFieldsGetComponent(listFields.data);
        const htmlInfo = `
          <div data-gjs-slugLofty="paymentRenderInfoData" data-gjs-name="Item List Read" style="padding: 10px;" >
            ${resFieldsReact}
          </div>
        `;
        data.append(htmlInfo);
      } else {
        const htmlInfo = `
          <div data-gjs-slugLofty="paymentRenderInfoData" data-gjs-name="Item List Read" style="padding: 10px;" >
            {{infoPay}}
          </div>
        `;
        data.append(htmlInfo);
      }
      if (getParamData?.data?.length > 0) {
        // setParamData(getParamData.data);
        const paramSetter = getParamData.data.map((index) => (
          {
            paramendpoint: index,
            state: '',
            param: '',
          }
        ));
        data.addAttributes({viewPayInfoParams: paramSetter});
        setParamData(paramSetter);
      }
      toastr.success('Actualizado');
    } catch (error) {
      toastr.error('Error al procesar informacion');
    }
  }

  const typesOfFields = [
    {value: 'variable', label: 'Variables'},
    // {value: 'dbfield', label: 'Campo Base de Datos'},
    {value: 'useparams', label: 'Parametros'},
    {value: 'static', label: 'Estatico'},
  ];

  function checkTypeOf(paramValue, compareTo) {
    if (paramValue === 'string' && (compareTo === 'string' || compareTo === 'database' || compareTo === 'relation')) {
      return true;
    }
    if (paramValue === compareTo) {
      return true;
    }
    return false;
  }

  function editTypeInput(input, typeSelect) {
    try {
      const temp = paramData;
      const nameValue = input.paramendpoint.value;
      const indexOfObjectToUpdate = temp.findIndex(
        (obj) => (obj.paramendpoint.value === nameValue),
      );
      if (indexOfObjectToUpdate !== -1) {
        temp[indexOfObjectToUpdate].state = typeSelect;
      }
      data.addAttributes({viewPayInfoParams: temp});
      setParamData(temp.map((item) => item));
      toastr.success('Actualizado');
    } catch (error) {
      toastr.error('Error al actualizar Dato');
    }
  }

  function editParamInput(input, value) {
    try {
      const temp = paramData;
      const nameValue = input.paramendpoint.value;
      const indexOfObjectToUpdate = temp.findIndex(
        (obj) => (obj.paramendpoint.value === nameValue),
      );
      if (indexOfObjectToUpdate !== -1) {
        temp[indexOfObjectToUpdate].param = value;
      }
      data.addAttributes({viewPayInfoParams: temp});
      setParamData(temp.map((item) => item));
    } catch (error) {
      toastr.error('Error al actualizar Dato');
    }
  }

  useEffect(() => {
    handlePaymentMethods();
    if (data?.attributes?.attributes?.paymentSelection) {
      handleEndpointList(data?.attributes?.attributes?.paymentSelection?._id);
    }
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Manejo de Visualizacion de Compra
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{background: '#242424', color: '#fff'}}>
        <Box
          component="div"
          style={{
            margin: '15px', padding: '5px',
          }}
        >
          {
            paymentMethodData?.length > 0 && (
              <Box>
                <div className="my-4">
                  <TextField
                    onChange={(e) => handleEndpointList(e.target.value)}
                    defaultValue={data?.attributes?.attributes?.paymentSelection?._id}
                    select
                    fullWidth
                    name="payment"
                    type="text"
                    label="Metodo de Pago:"
                    variant="filled"
                    style={{backgroundColor: '#D9D9D9', color: '#fff'}}
                  >
                    {
                      paymentMethodData.map(({_id, label}) => (
                        <MenuItem value={_id} key={_id}>
                          {label}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </div>
              </Box>
            )
            }
        </Box>
        <Box
          component="div"
          style={{
            margin: '15px', padding: '5px',
          }}
        >
          {
            endpointData?.length > 0 && (
              <Box>
                <div className="my-4">
                  <TextField
                    onChange={(e) => handleParamData(e.target.value)}
                    defaultValue={data?.attributes?.attributes?.endpointSelection?._id}
                    select
                    fullWidth
                    name="endpoint"
                    type="text"
                    label="Utilizar Servicio:"
                    variant="filled"
                    style={{backgroundColor: '#D9D9D9', color: '#fff'}}
                  >
                    {
                      endpointData.map(({_id, label}) => (
                        <MenuItem value={_id} key={_id}>
                          {label}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </div>
              </Box>
            )
            }
        </Box>
        <Box
          component="div"
          style={{
            margin: '15px',
            padding: '5px',
            height: paramData?.length > 0 ? '400px' : 'auto',
            overflowY: 'scroll',
          }}
        >
          {
              paramData?.map((index) => (
                <Box component="div" className={classes.inputInfoContainer} key={index}>
                  <Box component="div" className={classes.inputTitleInfo}>
                    {index.paramendpoint.label}
                  </Box>
                  <div className="my-4">
                    <TextField onChange={(e) => editTypeInput(index, e.target.value)} value={index?.state} select fullWidth name="type" type="text" label="Usar Parametro con:" variant="standard">
                      {
                          typesOfFields.map(({label, value}) => (
                            <MenuItem value={value} key={value}>
                              {label}
                            </MenuItem>
                          ))
                        }
                    </TextField>
                  </div>
                  {index.state === 'useparams' && (
                    <div>
                      <TextField select fullWidth name="pageparams" onChange={(e) => editParamInput(index, e.target.value)} value={index?.param} type="text" label="Parametros:" variant="standard">
                        {
                          pageParams?.map(({value, name}) => (
                            <MenuItem value={value} key={value}>
                              {name}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                  )}
                  {index.state === 'variable' && (
                    <div>
                      <TextField select fullWidth name="variable" onChange={(e) => editParamInput(index, e.target.value)} value={index?.param} type="text" label="Estados:" variant="standard">
                        {
                          variables?.filter(
                            (fieldData) => (checkTypeOf(index.paramendpoint.type, fieldData.type)),
                          )?.map(({value, name}) => (
                            <MenuItem value={value} key={value}>
                              {name}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                  )}
                  {index.state === 'static' && (
                    <div>
                      <TextField
                        fullWidth
                        name="type"
                        onChange={(e) => editParamInput(index, e.target.value)}
                        value={index?.param}
                        type="text"
                        label="Valor:"
                        variant="standard"
                      />
                    </div>
                  )}
                  {/* {index.state === 'dbfield' && (
                    <div>
                      <TextField
                        select
                        fullWidth
                        name="type"
                        onChange={(e) => editParamInput(index, e.target.value)}
                        value={index?.param}
                        type="text"
                        label="Parametros:"
                        variant="standard"
                      >
                        {
                          fields?.filter(
                            (fieldData) => (checkTypeOf(index.paramendpoint.type, fieldData.type)),
                          )?.map(({value, name}) => (
                            <MenuItem value={value} key={value}>
                              {name}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </div>
                  )} */}
                </Box>
              ))
            }
        </Box>
        <Box className={classes.buttonAreaContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            className={classes.buttonStyle}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
