/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;
  // eslint-disable-next-line consistent-return

  function handleButtonDynamicPayment(model) {
    const {handleViewCartModal} = opts;
    handleViewCartModal(model);
  }

  function setDataHandler(model) {
    const fieldsParamsTraits = {
      name: 'visualizeCartInfo',
      type: 'button',
      text: 'Manejar Visualizacion de Compra',
      command: () => handleButtonDynamicPayment(model),
    };
    model.addTrait(fieldsParamsTraits);
  }
  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value === ('viewCartInfoComponent')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateComponent());
      },
      updateComponent() {
        // console.log(this.model);
        try {
          if (this.model.attributes.sluglofty.includes('viewCartInfoComponent')) {
            // console.log('Hey');
            this.model.removeTrait('visualizeCartInfo');
            setDataHandler(this.model);
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
