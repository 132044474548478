export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
    .gjs-dropdown-menu-container {
      position: relative;
      display: block;
    }
    .gjs-dropdown-menu-trigger {
      padding: 10px;
      backgroundColor: #333;
      color: #000;
      cursor: pointer;
    }
    .gjs-dropdown-menu-content {
      position: absolute;
      width: 100%;
      min-height: 200px;
      backgroundColor: #fff;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 5;
    }
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center">
      <svg class="icon-lofty-component-block" width="32px" height="32px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="icon-lofty-component-block">
      <path d="M10 15h12v1H10zm-4-3h2v-2H6zm4-1h12v-1H10zm-4 6h2v-2H6zm4 4h12v-1H10zm-4 1h2v-2H6zM23 3v3a1.001 1.001 0 0 1-1 1H2a1.001 1.001 0 0 1-1-1V3a1.001 1.001 0 0 1 1-1h20a1.001 1.001 0 0 1 1 1zm-.999 3H22V3H2v3h20.001z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
    </div>
    <div class="gjs-block-label">
      ${opts.label}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Componente de Menu para items con Dropdown.', id: 'component-dropdown-menu-lofty-editor'},
    content: `
      <div
        data-gjs-slugLofty="dropdownMenuContainer"
        data-gjs-name="Contenedor de Menu"
        data-gjs-droppable="false"
        class="gjs-dropdown-menu-container"
        style="padding: 10px"
        data-gjs-resizable="{bc: 1}"
      >
        <div
          data-gjs-slugLofty="dropdownMenuTrigger"
          data-gjs-name="Seccion de Inicio del Menu"
          class="gjs-dropdown-menu-trigger"
          style="padding: 10px"
          data-gjs-resizable="{bc: 1}"
        >
          Menu
        </div>
        <div
          data-gjs-slugLofty="dropdownMenuContent"
          data-gjs-name="Contenido del Menu"
          class="gjs-dropdown-menu-content"
          style="display: none;"
          data-gjs-resizable="{bc: 1}"
        ></div>
      </div>
    ${style}`,
  });
};
