import grapesjs from 'grapesjs';
import loadMainComponents from './mainCarouselComponent';
// import loadComponents from './components';
import loadBlocks from './blocks';

export default grapesjs.plugins.add('gjs-single-dynamic-carousel', (editor, opts = {}) => {
  const options = {
    label: 'Carrusel Dinamico',
    name: 'singleDynamicCarousel',
    category: 'Otros',
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const name in options) {
    if (!(name in opts)) opts[name] = options[name];
  }
  loadBlocks(editor, options);
  // loadComponents(editor, opts);
  loadMainComponents(editor, opts);
});
