import grapesjs from 'grapesjs';
import loadBlocks from './blocks';
import loadComponents from './components';

export default grapesjs.plugins.add('gjs-pdf-param-component', (editor, opts = {}) => {
  const config = {
    blocks: [
      'pdfParams',
    ],
    name: 'pdfParams',
    stylePrefix: 'gjs-',
    label: 'Componente de Parametros',
    defText: 'Sample',
    category: 'Dinamismo',
    ...opts,
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const name in config) {
    if (!(name in opts)) opts[name] = config[name];
  }
  loadBlocks(editor, config);
  loadComponents(editor, opts);
});
