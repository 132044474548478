/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable no-await-in-loop */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */
import {
  listDatabases, listEndpoint,
  listFieldsDatabase, listDataSimulatorDatabaseAdmin,
  listSimulatorUserProject, listUserProject, getOnePageApiPages,
  listFieldUserProject, getOneDatabase, getOneEndpoint, listParamsEndpoint,
  listFunctionsPage,
} from 'api-lofty';
import {
  databaseFieldsGetComponent,
  getFieldsBucket, databaseFieldsPostComponent,
  // getAllReadFieldsForParams,
  getAllReadRelationFieldsForParams,
  getAllFieldsForCustomParams,
  databaseFieldsUpdateComponent,
} from '../../util/databaseFieldsComponent';
import {searchForReadDB} from '../../util/searchParentComponent';
import {eliminateInnerComponent, restoreInnerComponent} from '../../util/grapeJsCanvasFunctions';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;
  let countsCanRemove = 1;
  // let relationInputArray = [];
  // let temporalStoreUserField = [];

  /**
   * ===================================
   * ==== CORE FUNCTIONS ===
   * ===================================
   * Has Params
   * Get All Page Params
   * Buttons Handlers
   * Get All Functions of Page
   */
  async function hasParamsInPage(token, idPage) {
    const resPage = await getOnePageApiPages({token, _id: idPage});
    const urlParamsRaw = resPage.data.url.split('/');
    const hasParams = urlParamsRaw.some((index) => (index.includes(':')));
    return hasParams;
  }

  async function getAllPageParams(token, idPage) {
    const resPage = await getOnePageApiPages({token, _id: idPage});
    const urlParamsRaw = resPage.data.url.split('/');
    const filterData = [];
    urlParamsRaw.forEach((index) => {
      if (index.includes(':')) {
        const temp = index.split(':');
        const paramData = temp[1];
        filterData.push({value: paramData, name: paramData});
      }
    });
    return filterData;
  }

  function handleButtonClickOpenManager(info, model) {
    const {handleInputManager} = opts;
    // console.log(openForm);
    handleInputManager(info, model);
  }

  async function getFunctionPagesData() {
    const token = opts.token;
    const idProject = opts.idProject;
    const idPage = opts.idPage;
    const resData = await listFunctionsPage({token, idPage, idProject});
    return resData.data;
  }

  /**
    =========================================================
    * Handle Open Custom Endpoint Modal
    =========================================================

    * Opens the modal to manage the custom endpoint.
    * @param {*fields From Database*} fieldData
    * @param {*current Component*} model
    * @param {*local and global variables*} variables
    * @param {*params from the actual page*} paramData
    * May have empty values if not found.
    * Only for custom

    =========================================================
  */
  function handleButtonClickOpenCustomEndpointManager(model, variables, paramData, fieldData) {
    const {handleCustomEndpointManager} = opts;
    handleCustomEndpointManager(
      variables,
      fieldData,
      paramData,
      model,
    );
  }

  /**
    =========================================================
    * Trait Button to Call Modal Custom Endpoint
    =========================================================

    * Creates the trait for the component
    * @param {*fields From Database*} fieldData
    * @param {*current Component*} model
    * @param {*local and global variables*} variables
    * @param {*params from the actual page*} paramData
    * May have empty values if not found.
    * Only for custom

    =========================================================
  */

  function modalCustomEndpointCall(model, variables, paramData, fieldData) {
    const traitCustomEndpointHandler = {
      name: 'buttonCustomEndpointModal',
      type: 'button',
      text: 'Manejar Parametros de Servicio',
      full: true,
      command: () => handleButtonClickOpenCustomEndpointManager(model, variables, paramData, fieldData),
    };
    model.addTrait(traitCustomEndpointHandler);
  }

  /**
    =========================================================
    * Handle Capture Data for Custom Endpoint
    =========================================================

    * Captures all data if found.
    * @param {*Verifies if parent is a ReadDB Component*} findReadDBParent
    * @param {*current Component*} model
    * @param {*verifies if variables exists*} hasVariables
    * @param {*verifies if page has params*} findParams
    * May have empty values if not found.
    * Only for custom

    =========================================================
  */
  async function captureDataForCustomEndpointCall(model, findParams, hasVariables, findReadDBParent) {
    try {
      model.removeTrait('buttonCustomEndpointModal');
      const idPage = opts.idPage;
      const token = opts.token;
      const idProject = opts.idProject;
      let getPageParams = [];
      let variableData = [];
      let relationFields = [];
      if (findParams) {
        getPageParams = await getAllPageParams(token, idPage);
      }
      if (findReadDBParent) {
        const findParent = searchForReadDB(model);
        relationFields = await getAllFieldsForCustomParams(findParent, token, idProject);
      }
      if (hasVariables) {
        const varData = [];
        const localVars = opts.localVars;
        const globalVars = opts.globalVars;
        // 'boolean' | 'string' | 'number' | 'arrayDatabase' | 'database'
        localVars.filter((index) => (index.type !== 'arrayDatabase'))
          .forEach((variable) => {
            varData.push({name: `${variable.label}--${variable.type}`, type: variable.type, value: variable._id});
          });
        globalVars.filter((index) => (index.typeValue !== 'arrayDatabase'))
          .forEach((variable) => {
            varData.push({name: `${variable.label}--${variable.typeValue}`, type: variable.typeValue, value: variable._id});
          });
        variableData = varData;
      }
      modalCustomEndpointCall(model, variableData, getPageParams, relationFields);
    } catch (error) {
      // console.log(error);
    }
  }

  /**
    =========================================================
    * Type of Filter Setter
    =========================================================

    * Sets the type of Filter if passes the given validations.
    * @param {*default value from call*} defaultValueSet
    * May either be saved attribute or param if old version
    * @param {*the current component*} model
    =========================================================
  */

  async function typeOfFilterSetter(model, defaultValueSet) {
    const token = opts.token;
    const idPage = opts.idPage;
    const findParams = await hasParamsInPage(token, idPage);

    // Search for Variables
    let hasVariables = false;
    const localVars = opts.localVars;
    const globalVars = opts.globalVars;
    if (localVars.length > 0 || globalVars.length > 0) {
      hasVariables = true;
    }

    // Search for Parent Component
    const findReadDBParent = searchForReadDB(model);

    // if has value
    const traitTypeFilterValue = defaultValueSet;

    if (findParams || findReadDBParent || hasVariables) {
      const typeOfFilterOptions = [];
      if (findParams) {
        typeOfFilterOptions.push({name: 'Parametros', value: 'params'});
      }
      if (findReadDBParent) {
        typeOfFilterOptions.push({name: 'Por Componente de Lectura', value: 'readcomponent'});
      }
      if (hasVariables) {
        typeOfFilterOptions.push({name: 'Por Variables', value: 'variables'});
      }
      const traitTypeFilter = {
        type: 'select',
        name: 'typeOfFilterManager',
        label: 'Tipo de filtro',
        changeProp: 1,
        options: typeOfFilterOptions,
        default: traitTypeFilterValue,
      };
      model.addTrait(traitTypeFilter);
    }
  }

  /**
    =========================================================
    * Restore Relation Input Manager Trait
    =========================================================

    * Restores the trait for Input Manager.
    * If the object relationInputs exists in attributes object
    * then restore component.

    =========================================================
  */

  async function restoreInputManager(model) {
    const filterData = [];
    const idPage = opts.idPage;
    const token = opts.token;
    const resPage = await getOnePageApiPages({token, _id: idPage});
    const urlParamsRaw = resPage.data.url.split('/');
    urlParamsRaw.forEach((index) => {
      if (index.includes(':')) {
        const temp = index.split(':');
        const paramData = temp[1];
        filterData.push({value: paramData, name: paramData});
      }
    });
    const traitUseParamForRelation = {
      type: 'button',
      text: 'Manejar Inputs de Relaciones',
      name: 'buttonRelationInputManager',
      full: true,
      command: () => handleButtonClickOpenManager(filterData, model),
    };
    model.addTrait(traitUseParamForRelation);
  }

  async function getRelationInfo(relationIDSeeker, token, idProject) {
    const selectionData = await listDataSimulatorDatabaseAdmin({
      token,
      idDatabase: relationIDSeeker,
      idProject,
    });
    return selectionData.data;
  }

  async function getForeignFields(relationIDSeeker, token, idProject) {
    const listFieldsForeignDatabase = await listFieldsDatabase({
      token,
      idProject,
      idDatabase: relationIDSeeker,
    });
    return listFieldsForeignDatabase.data;
  }

  async function getMainData(relationArray, token, idProject) {
    const retVal = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < relationArray.length; index++) {
      const tempRelation = await getRelationInfo(relationArray[index], token, idProject);
      const tempFields = await getForeignFields(relationArray[index], token, idProject);
      retVal[relationArray[index]] = {};
      retVal[relationArray[index]].fields = tempFields;
      retVal[relationArray[index]].data = tempRelation;
    }
    return retVal;
  }

  function restoreComponent(component) {
    const componentData = component.get('components');
    const eliminateComponent = componentData.filter((index) => (index));
    eliminateComponent.map((index) => (index.collection.remove(index)));
  }

  async function restoreEndpointTrait(model, type, keyData) {
    try {
      const traitValue = model.attributes.dbSelect;
      const token = opts.token;
      const idDatabase = traitValue;
      const resData = await listEndpoint({token, idDatabase});
      const values = [];
      let isTypeBucket = false;
      if (type === 'Read') {
        // isTypeBucket = await getOneDatabase({token, _id: idDatabase});
        if (resData.data.length > 0) {
          // eslint-disable-next-line array-callback-return
          resData.data.forEach((index) => {
            if (
              index.typeFunction === 'list' || index.typeFunction === 'one'
              || index.typeFunction === 'customList' || index.typeFunction === 'customOne'
            ) {
              values.push({value: index._id, name: `${index?.url} -- ${index?.typeFunction}`});
            }
          });
        }
        // if (isTypeBucket.data.isBucket) {
        //   const traitEndpoint = {
        //     type: 'select',
        //     name: 'endpointSelect',
        //     label: 'Endpoint Selection',
        //     changeProp: 1,
        //     options: [{value: 'sampleONLY', name: 'SAMPLE ONLY'}],
        //     default: '',
        //   };
        //   model.addTrait(traitEndpoint);
        //   return;
        // }
      } else if (type === 'AddDBContainer') {
        if (resData.data.length > 0) {
          isTypeBucket = await getOneDatabase({token, _id: idDatabase});
          // eslint-disable-next-line array-callback-return
          resData.data.map((index) => {
            if (index.typeFunction === 'create' || index.typeFunction === 'customCreate') {
              values.push({value: index._id, name: `${index?.url} -- ${index?.typeFunction}`});
            }
          });
          if (isTypeBucket.data.isBucket) {
            const traitAddition = {
              type: 'select',
              name: 'allowMultipleAttachment',
              label: 'Subir Archivos',
              changeProp: 1,
              options: [{value: 'single', name: '1 Archivo'}, {value: 'multiple', name: 'Multiples Archivos'}],
              default: model.attributes.allowMultipleAttachment || 'single',
            };
            model.addTrait(traitAddition);
          }
        }
      } else if (type === 'Update') {
        if (resData.data.length > 0) {
          // eslint-disable-next-line array-callback-return
          resData.data.map((index) => {
            if (index.typeFunction === 'update') {
              values.push({value: index._id, name: `${index?.url} -- ${index?.typeFunction}`});
            }
          });
        }
      } else if (type === 'Delete') {
        if (resData.data.length > 0) {
          // eslint-disable-next-line array-callback-return
          resData.data.map((index) => {
            if (index.typeFunction === 'delete') {
              values.push({value: index._id, name: `${index?.url} -- ${index?.typeFunction}`});
            }
          });
        }
      }
      const traitEndpoint = {
        type: 'select',
        name: 'endpointSelect',
        label: 'Seleccion de Servicio',
        changeProp: 1,
        options: values,
        default: keyData,
      };
      model.addTrait(traitEndpoint);
      const searchForLoggedUserTrait = model.attributes.useCurrentUserAddDatabase;
      const searchForComponentsRelation = model.attributes.attributes.relationInputs;
      if (searchForLoggedUserTrait) {
        const traitUseLoggedUser = {
          type: 'select',
          name: 'useCurrentUserAddDatabase',
          label: 'Usar usuario activo',
          changeProp: 1,
          options: [{value: 'nouser', name: 'No'}, {value: 'usecurrent', name: 'Usar Usuario Activo'}],
          default: searchForLoggedUserTrait,
        };
        model.addTrait(traitUseLoggedUser);
      }
      if (searchForComponentsRelation) {
        restoreInputManager(model);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  /**
    =========================================================
    * Restore Custom Endpoint Filters
    =========================================================

    * Search for all possible filters for custom endpoint
    * Endpoint must have at least one parameter
    * @param {*the current custom endpoint*} endpointValue
    * @param {*the current component*} model
    * Only for custom

    =========================================================
  */
  async function restoreFilterCustomEndpoint(model, endpointValue) {
    const token = opts.token;
    const idPage = opts.idPage;
    const getParamData = await listParamsEndpoint({
      idEndpoint: endpointValue.data._id,
      idProject: endpointValue.data.idProject,
      token,
    });
    /**
      * SEARCH FOR FILTER TYPES
      *
      * If Has : then params exists
      * If has variables (local/global) then can use vars
      * If Parent Component is ReadDB use parentComponent
    */

    const findParams = await hasParamsInPage(token, idPage);

    // Search for Variables
    let hasVariables = false;
    const localVars = opts.localVars;
    const globalVars = opts.globalVars;
    if (localVars.length > 0 || globalVars.length > 0) {
      hasVariables = true;
    }

    // Search for Parent Component
    const findReadDBParent = searchForReadDB(model);

    if ((findParams || hasVariables || findReadDBParent) && getParamData.data.length > 0) {
      captureDataForCustomEndpointCall(model, findParams, hasVariables, findReadDBParent);
    }
  }

  function validDestination(target, destination, section) {
    try {
      if (destination.attributes.sluglofty.includes(section)) {
        return true;
      }
      const parentComponent = destination.parent();
      if (parentComponent !== undefined && parentComponent.attributes.sluglofty.includes(section)) {
        return true;
      } else if (parentComponent === undefined) {
        return false;
      } else {
        return validDestination(target, parentComponent, section);
      }
    } catch (error) {
      // console.log(error);
      return false;
    }
  }

  dc.addType('input', {
    isComponent: (el) => el.tagName === 'INPUT',
    model: {
      defaults: {
        traits: [
          'placeholder',
        ],
      },
    },
  });

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (
          attr['data-gjs-sluglofty']
          && (attr['data-gjs-sluglofty'].value.includes('DBContainer') || attr['data-gjs-sluglofty'].value.includes('fieldRelationDatabaseAddContainer'))) {
        // console.log('print me if true');
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, 'change:dbSelect', this.updateDatabaseContainer);
        this.listenTo(model, 'change:endpointSelect', this.importDBFields);
        this.listenTo(model, 'change:elimination', this.addEliminateToRead);
        this.listenTo(model, 'change:alertTrait', this.alertAdder);
        this.listenTo(model, 'change:useCurrentUserAddDatabase', this.eliminateUserFieldAdd);
        this.listenTo(model, 'change:useParamToAddWithParamRelation', this.selectionAddParamRelation);
        this.listenTo(model, 'change:typeOfFilterManager', this.selectTypeOfReadManager);
        this.listenTo(model, 'change:customFunctionPlugin', this.selectCustomFunction);
        this.listenTo(model, '', this.getDatabasesData());
      },
      eliminateUserFieldAdd() {
        if (this.model.attributes.sluglofty === 'AddDBContainer' || this.model.attributes.sluglofty === 'UpdateDBContainer') {
          const statusUseUser = this.model.getTrait('useCurrentUserAddDatabase').attributes.value;
          if (statusUseUser === 'usecurrent') {
            eliminateInnerComponent(this.model, 'fieldUserDatabaseAddContainer');
          } else {
            const token = opts.token;
            const idProject = opts.idProject;
            const fieldInfo = this.model.attributes.attributes.userfieldInfo;
            if (this.model.attributes.sluglofty === 'AddDBContainer') {
              restoreInnerComponent(this.model, fieldInfo, 'formAddDatabase', 'userfield', token, idProject);
            } else {
              restoreInnerComponent(this.model, fieldInfo, 'formUpdateDatabase', 'userfield', token, idProject);
            }
          }
        }
      },
      addEliminateToRead() {
        if (this.model.attributes.sluglofty.includes('Read')) {
          const statusEliminate = this.model.getTrait('elimination');
          const valueStatus = statusEliminate.attributes.value;
          if (valueStatus) {
            const htmlAdder = `
              <button 
                data-gjs-slugLofty="eliminateRead"
                data-gjs-resizable="{bc: 1}"
                data-gjs-name="Eliminate Read"
                data-gjs-draggable="[title=crudContainer]"
              >
                <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Eliminate</div>
              </button>
            `;
            this.model.append(htmlAdder);
          } else {
            const componentData = this.model.get('components');
            const toEliminate = componentData.filter((componentModel) => (componentModel.attributes.sluglofty === 'eliminateRead'));
            toEliminate[0].collection.remove(toEliminate[0]);
          }
        }
      },
      async getDatabasesData() {
        if (this.model.attributes.sluglofty.includes('DBContainer')) {
          this.model.removeTrait('endpointSelect');
          this.model.removeTrait('filterAdd');
          this.model.removeTrait('elimination');
          this.model.removeTrait('typeOfFilterManager');
          this.model.removeTrait('buttonCustomEndpointModal');
          this.model.removeTrait('filterUpdate');
          this.model.removeTrait('filterDelete');
          this.model.removeTrait('buttonRelationInputManager');
          this.model.removeTrait('dbSelect');
          try {
            const token = opts.token;
            const idProject = opts.idProject;
            const idPage = opts.idPage;
            const resData = await listDatabases({token, idProject});
            const resUserProject = await listUserProject({idProject, token});
            const resPage = await getOnePageApiPages({token, _id: idPage});
            let status = false;
            let userAuth = false;
            if (resUserProject.data.length > 0) {
              status = true;
            }
            if (resPage.data.authProtocol === 'active' || resPage.data.authProtocol === 'inactive') {
              userAuth = true;
            }
            const values = [];
            resData.data.map((index) => values.push({value: index._id, name: index.label}));
            if (status && userAuth && this.model.attributes.sluglofty.includes('Read')) {
              values.push({value: 'getuser', name: 'Lectura Usuario'});
            }
            if (this.model.attributes.dbSelect) {
              const traitAddition = {
                type: 'select',
                name: 'dbSelect',
                label: 'Seleccion de Coleccion',
                changeProp: 1,
                options: values,
                default: this.model.attributes.dbSelect,
              };
              this.model.addTrait(traitAddition);
            } else {
              const traitAddition = {
                type: 'select',
                name: 'dbSelect',
                label: 'Seleccion de Coleccion',
                changeProp: 1,
                options: values,
                default: '',
              };
              this.model.addTrait(traitAddition);
            }
            /* --- DATA RESTORATION --- */
            if (this.model.attributes.sluglofty.includes('Read')) { // Read Restoration
              const type = 'Read';
              if (this.model.attributes.endpointSelect && this.model.attributes.endpointSelect === 'getuser') {
                const traitEndpoint = {
                  type: 'select',
                  name: 'endpointSelect',
                  label: 'Seleccion de Servicio',
                  changeProp: 1,
                  options: [{value: 'getuser', name: 'Campos Usuario'}],
                  default: this.model.attributes.endpointSelect,
                };
                this.model.addTrait(traitEndpoint);
              } else if (this.model.attributes.endpointSelect) {
                try {
                  restoreEndpointTrait(this.model, type, this.model.attributes.endpointSelect);
                  // FILTER
                  const endpoint = await getOneEndpoint({token, _id: this.model.attributes.endpointSelect});
                  if (endpoint.data.typeFunction.includes('custom')) {
                    restoreFilterCustomEndpoint(this.model, endpoint);
                  } else if (this.model.attributes.typeOfFilterManager) {
                    // restoreFilterTrait(this.model, type, this.model.attributes.filterAdd);
                    typeOfFilterSetter(this.model, this.model.attributes.typeOfFilterManager);
                    this.selectTypeOfReadManager();
                  } else if (this.model.attributes.filterAdd && !this.model.attributes.typeOfFilterManager) {
                    typeOfFilterSetter(this.model, 'params');
                    this.selectTypeOfReadManager();
                  } else if (endpoint.data.typeFunction !== 'list') {
                    typeOfFilterSetter(this.model, '');
                  }
                } catch (error) {
                  // console.log(error);
                }
              } else if (this.model.attributes.dbSelect) {
                restoreEndpointTrait(this.model, type, '');
              }
              const traitEliminate = {
                type: 'checkbox',
                name: 'elimination',
                label: 'Eliminar',
                changeProp: 1,
              };
              this.model.addTrait(traitEliminate);
            } else if (this.model.attributes.sluglofty.includes('AddDBContainer')) { // Add Restoration
              const type = 'AddDBContainer';
              if (this.model.attributes.endpointSelect) {
                try {
                  restoreEndpointTrait(this.model, type, this.model.attributes.endpointSelect);
                } catch (error) {
                  // console.log(error);
                }
              } else if (this.model.attributes.dbSelect) {
                restoreEndpointTrait(this.model, type, '');
              }
            } else if (this.model.attributes.sluglofty.includes('Update')) { // Update Restoration
              const type = 'Update';
              if (this.model.attributes.endpointSelect) {
                try {
                  restoreEndpointTrait(this.model, type, this.model.attributes.endpointSelect);
                  // FOR FILTER
                  if (endpoint.data.typeFunction.includes('custom')) {
                    restoreFilterCustomEndpoint(this.model, endpoint);
                  } else if (this.model.attributes.typeOfFilterManager) {
                    // restoreFilterTrait(this.model, type, this.model.attributes.filterAdd);
                    typeOfFilterSetter(this.model, this.model.attributes.typeOfFilterManager);
                    this.selectTypeOfReadManager();
                  } else if (this.model.attributes.filterUpdate && !this.model.attributes.typeOfFilterManager) {
                    typeOfFilterSetter(this.model, 'params');
                  } else {
                    typeOfFilterSetter(this.model, '');
                  }
                } catch (error) {
                  //
                }
              } else if (this.model.attributes.dbSelect) {
                restoreEndpointTrait(this.model, type, '');
              }
            } else if (this.model.attributes.sluglofty.includes('Delete')) { // Delete Restoration
              const type = 'Delete';
              try {
                if (this.model.attributes.endpointSelect) {
                  restoreEndpointTrait(this.model, type, this.model.attributes.endpointSelect);
                  // FOR FILTER
                  if (this.model.attributes.typeOfFilterManager) {
                    // restoreFilterTrait(this.model, type, this.model.attributes.filterAdd);
                    typeOfFilterSetter(this.model, this.model.attributes.typeOfFilterManager);
                    this.selectTypeOfReadManager();
                  } else if (this.model.attributes.filterDelete && !this.model.attributes.typeOfFilterManager) {
                    typeOfFilterSetter(this.model, 'params');
                    this.selectTypeOfReadManager();
                  } else {
                    typeOfFilterSetter(this.model, '');
                  }
                } else if (this.model.attributes.dbSelect) {
                  restoreEndpointTrait(this.model, type, '');
                }
              } catch (error) {
                //
              }
            }
            if (this.model.attributes.alertTrait) {
              const traitAlert = {
                type: 'select',
                name: 'alertTrait',
                label: 'Accion Exitosa',
                changeProp: 1,
                options: [{value: 'alert', name: 'Alerta'}, {value: 'function', name: 'funcion'}, {value: 'null', name: 'N/A'}],
                default: this.model.attributes.alertTrait,
              };
              this.model.addTrait(traitAlert);
              if (this.model.attributes.alertTrait === 'alert') {
                const alertMessage = {
                  type: 'text',
                  name: 'alertMessage',
                  label: 'Mensaje',
                  changeProp: 1,
                };
                this.model.addTrait(alertMessage);
              } else if (this.model.attributes.alertTrait === 'function') {
                const seekFunctions = await getFunctionPagesData();
                const values = [];
                if (seekFunctions.length > 0) {
                  seekFunctions.forEach((index) => {
                    values.push({value: index._id, name: index.label, content: index});
                  });
                  const traitAddition = {
                    type: 'select',
                    name: 'customFunctionPlugin',
                    label: 'Funcion',
                    changeProp: 1,
                    options: values,
                  };
                  this.model.addTrait(traitAddition);
                }
              }
            } else if (this.model.attributes.endpointSelect
              && (this.model.attributes.sluglofty.includes('Update') || this.model.attributes.sluglofty.includes('AddDBContainer'))) {
              const traitAlert = {
                type: 'select',
                name: 'alertTrait',
                label: 'Accion Exitosa',
                changeProp: 1,
                options: [{value: 'alert', name: 'Alerta'}, {value: 'function', name: 'funcion'}, {value: 'null', name: 'N/A'}],
                default: 'null',
              };
              this.model.addTrait(traitAlert);
            }
            // if (this.model.attributes.alertMessage) {
            //   const alertMessage = {
            //     type: 'text',
            //     name: 'alertMessage',
            //     label: 'Mensaje',
            //     changeProp: 1,
            //   };
            //   this.model.addTrait(alertMessage);
            // }
          } catch (error) {
          // console.log(error);
          }
        }
      },
      async updateDatabaseContainer() {
        if (this.model.attributes.sluglofty.includes('Read')) {
          // console.log('here');
          try {
            restoreComponent(this.model);
            this.model.removeTrait('endpointSelect');
            this.model.removeTrait('filterAdd');
            this.model.removeTrait('elimination');
            this.model.removeTrait('typeOfFilterManager');
            this.model.removeTrait('buttonCustomEndpointModal');
            // ELIMINATE CUSTOM TRAITS IF EXIST
            const traitData = this.model.get('traits');
            const eliminateCustoms = [];
            traitData.map((trait) => {
              if (trait.attributes.label.includes('Parametro:')) {
                eliminateCustoms.push({name: trait.id});
              }
            });
            eliminateCustoms.forEach((index) => (this.model.removeTrait(index.name)));

            countsCanRemove += 1;
            const dbSelection = this.model.getTrait('dbSelect');
            const traitValue = dbSelection.attributes.value;
            if (traitValue === 'getuser') {
              const traitAddition = {
                type: 'select',
                name: 'endpointSelect',
                label: 'Seleccion de Servicio',
                changeProp: 1,
                options: [{value: 'getuser', name: 'Campos Usuario'}],
                default: '',
              };
              this.model.addTrait(traitAddition);
            } else {
              try {
                const token = opts.token;
                const idDatabase = traitValue;
                const resData = await listEndpoint({token, idDatabase});
                // const isTypeBucket = await getOneDatabase({token, _id: idDatabase});
                const values = [];
                if (resData.data.length > 0) {
                  // eslint-disable-next-line array-callback-return
                  resData.data.forEach((index) => {
                    if (
                      index.typeFunction === 'list' || index.typeFunction === 'one'
                      || index.typeFunction === 'customList' || index.typeFunction === 'customOne'
                    ) {
                      values.push({value: index._id, name: `${index?.url} -- ${index?.typeFunction}`});
                    }
                  });
                  if (!this.model.getTrait('endpointSelect')) {
                    const traitAddition = {
                      type: 'select',
                      name: 'endpointSelect',
                      label: 'Seleccion de Servicio',
                      changeProp: 1,
                      options: values,
                      default: '',
                    };
                    this.model.addTrait(traitAddition);
                  }
                }
                // if (isTypeBucket.data.isBucket) {
                //   const traitAddition = {
                //     type: 'select',
                //     name: 'endpointSelect',
                //     label: 'Endpoint Selection',
                //     changeProp: 1,
                //     options: values,
                //     default: '',
                //   };
                //   this.model.addTrait(traitAddition);
                // }
              } catch (error) {
                // console.log(error);
              }
            }
            if (!this.model.getTrait('elimination')) {
              const traitEliminate = {
                type: 'checkbox',
                name: 'elimination',
                label: 'Eliminar',
                changeProp: 1,
              };
              this.model.addTrait(traitEliminate);
            }
          } catch (error) {
            // console.log(error);
          }
        } else if (this.model.attributes.sluglofty.includes('AddDBContainer')) {
          try {
            const dbSelection = this.model.getTrait('dbSelect');
            restoreComponent(this.model);
            const traitValue = dbSelection.attributes.value;
            const token = opts.token;
            const idDatabase = traitValue;
            const resData = await listEndpoint({token, idDatabase});
            const isTypeBucket = await getOneDatabase({token, _id: idDatabase});
            this.model.removeTrait('endpointSelect');
            this.model.removeTrait('useCurrentUserAddDatabase');
            this.model.removeTrait('alertTrait');
            this.model.removeTrait('customFunctionPlugin');
            this.model.removeTrait('alertMessage');
            this.model.removeTrait('allowMultipleAttachment');
            this.model.removeTrait('typeOfFilterManager');
            this.model.removeTrait('buttonCustomEndpointModal');
            const values = [];
            if (resData.data.length > 0) {
              // eslint-disable-next-line array-callback-return
              resData.data.map((index) => {
                if (index.typeFunction === 'create' || index.typeFunction === 'customCreate') {
                  values.push({value: index._id, name: `${index?.url} -- ${index?.typeFunction}`});
                }
              });
              const traitAddition = {
                type: 'select',
                name: 'endpointSelect',
                label: 'Seleccion de Servicio',
                changeProp: 1,
                options: values,
                default: '',
              };
              this.model.addTrait(traitAddition);
            }
            if (isTypeBucket.data.isBucket) {
              const traitAddition = {
                type: 'select',
                name: 'allowMultipleAttachment',
                label: 'Subir Archivos',
                changeProp: 1,
                options: [{value: 'single', name: '1 Archivo'}, {value: 'multiple', name: 'Multiples Archivos'}],
                default: 'single',
              };
              this.model.addTrait(traitAddition);
            }
          } catch (error) {
            // console.log(error);
          }
        } else if (this.model.attributes.sluglofty.includes('Delete')) {
          try {
            const dbSelection = this.model.getTrait('dbSelect');
            restoreComponent(this.model);
            this.model.removeTrait('endpointSelect');
            this.model.removeTrait('filterDelete');
            this.model.removeTrait('typeOfFilterManager');
            this.model.removeTrait('buttonCustomEndpointModal');
            const traitValue = dbSelection.attributes.value;
            const token = opts.token;
            const idDatabase = traitValue;
            const resData = await listEndpoint({token, idDatabase});
            const values = [];
            if (resData.data.length > 0) {
              // eslint-disable-next-line array-callback-return
              resData.data.map((index) => {
                if (index.typeFunction === 'delete') {
                  values.push({value: index._id, name: `${index?.url} -- ${index?.typeFunction}`});
                }
              });
              const traitAddition = {
                type: 'select',
                name: 'endpointSelect',
                label: 'Seleccion de Servicio',
                changeProp: 1,
                options: values,
                default: '',
              };
              this.model.addTrait(traitAddition);
            }
          } catch (error) {
            // console.log(error);
          }
        } else if (this.model.attributes.sluglofty.includes('Update')) {
          try {
            const dbSelection = this.model.getTrait('dbSelect');
            restoreComponent(this.model);
            this.model.removeTrait('alertTrait');
            this.model.removeTrait('customFunctionPlugin');
            this.model.removeTrait('endpointSelect');
            this.model.removeTrait('filterUpdate');
            this.model.removeTrait('alertMessage');
            this.model.removeTrait('typeOfFilterManager');
            this.model.removeTrait('buttonCustomEndpointModal');
            const traitValue = dbSelection.attributes.value;
            const token = opts.token;
            const idDatabase = traitValue;
            const resData = await listEndpoint({token, idDatabase});
            const values = [];
            if (resData.data.length > 0) {
              // eslint-disable-next-line array-callback-return
              resData.data.map((index) => {
                if (index.typeFunction === 'update' || index.typeFunction === 'customUpdate') {
                  values.push({value: index._id, name: `${index?.url} -- ${index?.typeFunction}`});
                }
              });
              const traitAddition = {
                type: 'select',
                name: 'endpointSelect',
                label: 'Seleccion de Servicio',
                changeProp: 1,
                options: values,
                default: '',
              };
              this.model.addTrait(traitAddition);
            }
          } catch (error) {
            // console.log(error);
          }
        }
      },
      async importDBFields() {
        if (this.model.attributes.sluglofty.includes('Read')) {
          let traitValue = '';
          // ELIMINATE CUSTOM TRAITS IF EXIST
          const traitData = this.model.get('traits');
          const eliminateCustoms = [];
          traitData.map((trait) => {
            if (trait.attributes.label.includes('Parametro:')) {
              eliminateCustoms.push({name: trait.id});
            }
          });
          eliminateCustoms.forEach((index) => (this.model.removeTrait(index.name)));
          if (this.model.getTrait('dbSelect').attributes.value !== '') {
            traitValue = this.model.getTrait('dbSelect').attributes.value;
          } else {
            traitValue = this.model.attributes.dbSelect;
          }
          const endpointSelection = this.model.getTrait('endpointSelect');
          const endpointValue = endpointSelection.attributes.value;
          if (countsCanRemove > 1) {
            restoreComponent(this.model);
          }
          if (traitValue === 'getuser') {
            try {
              const token = opts.token;
              const idProject = opts.idProject;
              // const dbSelection = this.model.getTrait('dbSelect');
              // const traitValue = dbSelection.attributes.value;
              const resFields = await listFieldUserProject({token, idProject});
              let htmlAdder = '';
              htmlAdder += `
                  <div
                    data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-valuelofty="loftyEmail"
                    data-gjs-name="Correo Lofty"
                    data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty Email Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                      {{ loftyEmail }}
                    </div>
                  </div>
                  <div
                    data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
                    data-gjs-valuelofty="loftyVerified"
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-name="Lofty Verificado"
                    data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty Verified" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                      {{ loftyVerified }}
                    </div>
                  </div>
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
                    data-gjs-valuelofty="loftyTypeRegister"
                    data-gjs-name="Lofty Tipo de Registro"
                    data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty Type Register Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                      {{ loftyTypeRegister }}
                    </div>
                  </div>
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
                    data-gjs-valuelofty="loftyPassword"
                    data-gjs-name="Lofty Contraseña"
                    data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty Password Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                      {{ loftyPassword }}
                    </div>
                  </div>
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
                    data-gjs-valuelofty="loftyName"
                    data-gjs-name="Lofty Nombre"
                    data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty Name Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                      {{ loftyName }}
                    </div>
                  </div>
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
                    data-gjs-valuelofty="loftyRole"
                    data-gjs-name="Lofty Rol"
                    data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty Role Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                      {{ loftyRole }}
                    </div>
                  </div>
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="userDefaultFieldDatabaseContainer"
                    data-gjs-valuelofty="loftyCreatedAt"
                    data-gjs-name="Lofty Creado en"
                    data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="userDefaultFieldDatabaseText" data-gjs-name="Lofty CreatedAt Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                      {{ loftyCreatedAt }}
                    </div>
                  </div>
                `;
              resFields.data.map((index) => {
                htmlAdder += `
                    <div
                      data-gjs-resizable="{bc: 1}"
                      data-gjs-slugLofty="userFieldDatabaseContainer"
                      data-gjs-valuelofty="${index.name}"
                      data-gjs-name="${index.label}"
                      data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                      style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                    >
                      <div data-gjs-slugLofty="userFieldDatabaseText" data-gjs-name="${index.label} Texto" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                       {{ ${index.name} }}
                      </div>
                    </div>
                  `;
              });
              this.model.append(htmlAdder);
              const getModelData = this.model.get('components');
              getModelData.forEach((index) => index.set({draggable: (e, d) => validDestination(e, d, 'ReadDBContainer')}));
              // console.log(this.model);
            } catch (error) {
              // console.log(error);
            }
          } else {
            try {
              const token = opts.token;
              const idProject = opts.idProject;
              const idDatabase = traitValue;
              const fieldList = await listFieldsDatabase({token, idDatabase, idProject});
              const typeDB = await getOneDatabase({token, _id: idDatabase});
              const endpoint = await getOneEndpoint({token, _id: endpointValue});
              this.model.removeTrait('typeOfFilterManager');
              let htmlAdder = '';
              // funcion para setear segun el tipo
              const byTypeField = (field) => {
                if (field.type === 'textrich') {
                  return 'fieldDatabaseTextRich';
                } else {
                  return 'fieldDatabaseText';
                }
              };
              if (endpoint.data.typeFunction === 'list') {
                htmlAdder += '<div data-gjs-slugLofty="itemListEndpointDatabase" data-gjs-name="Item List Read" style="padding: 10px;" >';
                fieldList.data.map((index) => {
                  htmlAdder += `
                    <div
                    data-gjs-resizable="{bc: 1}"
                      data-gjs-slugLofty="fieldDatabaseContainer"
                      data-gjs-valuelofty="${index.name}"
                      data-gjs-name="${index.label}"
                      data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                      style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                    >
                      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="${byTypeField(index)}" data-gjs-name="${index.label} Texto" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                       {{ ${index.name} }}
                      </div>
                    </div>
                  `;
                });
                if (typeDB.data.isBucket) {
                  htmlAdder += `
                <div
                data-gjs-resizable="{bc: 1}"
                data-gjs-slugLofty="fieldBucketDatabaseContainer"
                data-gjs-valuelofty="loftyOriginalName"
                data-gjs-name="Archivo Original"
                data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
              >
                <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="fieldDatabaseloftyOriginalName" data-gjs-name="Archivo Original" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                 {{ loftyOriginalName }}
                </div>
              </div>
              <div
                data-gjs-resizable="{bc: 1}"
                data-gjs-slugLofty="fieldBucketDatabaseContainer"
                data-gjs-valuelofty="loftyFileName"
                data-gjs-name="Archivo Llave"
                data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
              >
                <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="fieldDatabaseloftyFileName" data-gjs-name="Archivo Llave" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                 {{ loftyFileName }}
                </div>
              </div>
              <div
                data-gjs-slugLofty="fieldBucketDatabaseContainer"
                data-gjs-valuelofty="loftyUrl"
                data-gjs-name="Url"
                data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                data-gjs-resizable="{bc: 1}"
              >
                <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="fieldDatabaseloftyUrl" data-gjs-name="URL" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                 {{ loftyUrl }}
                </div>
              </div>
                `;
                }
                htmlAdder += '</div>';
                this.model.append(htmlAdder);
                const getModelData = this.model.get('components');
                const formModelData = getModelData.filter((index) => (index.attributes.sluglofty === 'itemListEndpointDatabase'));
                // console.log(formModelData[0]);
                const functionSetter = formModelData[0].attributes.components.models;
                functionSetter.forEach((index) => index.set({draggable: (e, d) => validDestination(e, d, 'itemListEndpointDatabase')}));
                // console.log(this.model);
              } else if (endpoint.data.typeFunction === 'one') {
                const idPage = opts.idPage;
                /**
                 * SEARCH FOR FILTER TYPES
                 *
                 * If Has : then params exists
                 * If has variables (local/global) then can use vars
                 * If Parent Component is ReadDB use parentComponent
                 */

                const findParams = await hasParamsInPage(token, idPage);

                // Search for Variables
                let hasVariables = false;
                const localVars = opts.localVars;
                const globalVars = opts.globalVars;
                if (localVars.length > 0 || globalVars.length > 0) {
                  hasVariables = true;
                }

                // Search for Parent Component
                const findReadDBParent = searchForReadDB(this.model);

                // if has value
                const traitTypeFilterValue = this.model.attributes.typeOfFilterManager || '';

                if (findParams || findReadDBParent || hasVariables) {
                  const typeOfFilterOptions = [];
                  if (findParams) {
                    typeOfFilterOptions.push({name: 'Parametros', value: 'params'});
                  }
                  if (findReadDBParent) {
                    typeOfFilterOptions.push({name: 'Por Componente de Lectura', value: 'readcomponent'});
                  }
                  if (hasVariables) {
                    typeOfFilterOptions.push({name: 'Por Variables', value: 'variables'});
                  }
                  const traitTypeFilter = {
                    type: 'select',
                    name: 'typeOfFilterManager',
                    label: 'Tipo de filtro',
                    changeProp: 1,
                    options: typeOfFilterOptions,
                    default: traitTypeFilterValue,
                  };
                  this.model.addTrait(traitTypeFilter);
                  const byTypeField = (field) => {
                    if (field.type === 'textrich') {
                      return 'fieldDatabaseTextRich';
                    } else {
                      return 'fieldDatabaseText';
                    }
                  };
                  fieldList.data.map((index) => {
                    htmlAdder += `
                      <div
                        data-gjs-resizable="{bc: 1}"
                        data-gjs-slugLofty="fieldDatabaseContainer"
                        data-gjs-valuelofty="${index.name}"
                        data-gjs-name="${index.label}"
                        data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                        style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                      >
                        <div data-gjs-slugLofty="${byTypeField(index)}" data-gjs-name="${index.label} Text" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                         {{ ${index.name} }}
                        </div>
                      </div>
                    `;
                  });
                  if (typeDB.data.isBucket) {
                    htmlAdder += `
                  <div
                  data-gjs-resizable="{bc: 1}"
                  data-gjs-slugLofty="fieldBucketDatabaseContainer"
                  data-gjs-valuelofty="loftyOriginalName"
                  data-gjs-name="Archivo Original"
                  data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                  style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                >
                  <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="fieldDatabaseloftyOriginalName" data-gjs-name="Archivo Original" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                   {{ loftyOriginalName }}
                  </div>
                </div>
                <div
                  data-gjs-resizable="{bc: 1}"
                  data-gjs-slugLofty="fieldBucketDatabaseContainer"
                  data-gjs-valuelofty="loftyFileName"
                  data-gjs-name="Archivo Llave"
                  data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                  style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                >
                  <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="fieldDatabaseloftyFileName" data-gjs-name="Archivo Llave" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                   {{ loftyFileName }}
                  </div>
                </div>
                <div
                  data-gjs-resizable="{bc: 1}"
                  data-gjs-slugLofty="fieldBucketDatabaseContainer"
                  data-gjs-valuelofty="loftyUrl"
                  data-gjs-name="Url"
                  data-gjs-draggable="[title=crudContainer], [title=crudContainer] > [title=forRead]"
                  style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                >
                  <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="fieldDatabaseloftyUrl" data-gjs-name="URL" data-gjs-draggable="false" data-gjs-stylable="false" data-gjs-editable="false">
                   {{ loftyUrl }}
                  </div>
                </div>
                  `;
                  }
                  this.model.append(htmlAdder);
                  const getModelData = this.model.get('components');
                  getModelData.forEach((index) => index.set({draggable: (e, d) => validDestination(e, d, 'ReadDBContainer')}));
                }
              } else if (endpoint.data.typeFunction === 'customOne') {
                // const filterData = searchParentComponentForFields(this.model);
                const idPage = opts.idPage;
                /**
                 * SEARCH FOR FILTER TYPES
                 *
                 * If Has : then params exists
                 * If has variables (local/global) then can use vars
                 * If Parent Component is ReadDB use parentComponent
                 */

                const findParams = await hasParamsInPage(token, idPage);

                // Search for Variables
                let hasVariables = false;
                const localVars = opts.localVars;
                const globalVars = opts.globalVars;
                if (localVars.length > 0 || globalVars.length > 0) {
                  hasVariables = true;
                }

                // Search for Parent Component
                const findReadDBParent = searchForReadDB(this.model);

                const getParamData = await listParamsEndpoint({
                  idEndpoint: endpointValue,
                  idProject: endpoint.data.idProject,
                  token,
                });
                let paramValues = [];
                if (getParamData.data.length > 0) {
                  paramValues = getParamData.data.map((index) => ({
                    paramendpoint: {
                      value: index.name,
                      label: index.label,
                      type: index.type,
                      stringDefaultValues: index?.stringDefaultValues || [],
                    },
                    state: index?.stringDefaultValues?.length > 0 ? 'static' : 'useparams',
                  }));
                  this.model.addAttributes({customParams: paramValues});
                } else {
                  this.model.addAttributes({withoutParams: 'noparamsinendpoint'});
                }
                if (!findParams && !hasVariables && !findReadDBParent && getParamData.data.length > 0) {
                  // eslint-disable-next-line no-alert
                  alert('No se encontro parametros para el servicio personalizado');
                }
                //
                if ((findParams || hasVariables || findReadDBParent) && getParamData.data.length > 0) {
                  captureDataForCustomEndpointCall(this.model, findParams, hasVariables, findReadDBParent);
                  const listFieldsFromResponse = await listFieldsDatabase({token, idDatabase: endpoint?.data?.responseDatabase, idProject});
                  // console.log(listFieldsFromResponse);
                  const resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
                  const componentCustom = `
                  ${resFieldsReact}`;
                  this.model.append(componentCustom);
                  const getModelData = this.model.get('components');
                  getModelData.forEach((index) => index.set({draggable: (e, d) => validDestination(e, d, 'ReadDBContainer')}));
                } else {
                  const listFieldsFromResponse = await listFieldsDatabase({token, idDatabase: endpoint?.data?.responseDatabase, idProject});
                  // console.log(listFieldsFromResponse);
                  const resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
                  const componentCustom = `
                  ${resFieldsReact}`;
                  this.model.append(componentCustom);
                  const getModelData = this.model.get('components');
                  getModelData.forEach((index) => index.set({draggable: (e, d) => validDestination(e, d, 'ReadDBContainer')}));
                }
              } else {
                const idPage = opts.idPage;
                // const resPage = await getOnePageApiPages({token, _id: idPage});
                const getParamData = await listParamsEndpoint({
                  idEndpoint: endpointValue,
                  idProject: endpoint.data.idProject,
                  token,
                });
                /**
                 * SEARCH FOR FILTER TYPES
                 *
                 * If Has : then params exists
                 * If has variables (local/global) then can use vars
                 * If Parent Component is ReadDB use parentComponent
                 */

                const findParams = await hasParamsInPage(token, idPage);

                // Search for Variables
                let hasVariables = false;
                const localVars = opts.localVars;
                const globalVars = opts.globalVars;
                if (localVars.length > 0 || globalVars.length > 0) {
                  hasVariables = true;
                }

                // Search for Parent Component
                const findReadDBParent = searchForReadDB(this.model);
                const typeDB = await getOneDatabase({token, _id: idDatabase});
                let hasBucketFields = '';
                if (typeDB.data.isBucket) {
                  hasBucketFields = getFieldsBucket();
                }

                let paramValues = [];
                if (getParamData.data.length > 0) {
                  paramValues = getParamData.data.map((index) => ({
                    paramendpoint: {
                      value: index.name,
                      label: index.label,
                      type: index.type,
                      stringDefaultValues: index?.stringDefaultValues || [],
                    },
                    state: index?.stringDefaultValues?.length > 0 ? 'static' : 'useparams',
                  }));
                  this.model.addAttributes({customParams: paramValues});
                } else {
                  this.model.addAttributes({withoutParams: 'noparamsinendpoint'});
                }
                if (!findParams && !hasVariables && !findReadDBParent && getParamData.data.length > 0) {
                  // eslint-disable-next-line no-alert
                  alert('No se encontro parametros para el servicio personalizado');
                }
                if (endpoint.data.responseType === 'listdatabase') {
                  const listFieldsFromResponse = await listFieldsDatabase({token, idDatabase: endpoint?.data?.responseDatabase, idProject});
                  const resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
                  const componentCustom = `
                <div data-gjs-slugLofty="itemListEndpointDatabase" data-gjs-name="Item List Read" style="padding: 10px;" >
                  ${resFieldsReact}
                  ${hasBucketFields}
                </div>`;
                  this.model.append(componentCustom);
                } else if (endpoint.data?.responseType === 'string') {
                  const componentCustom = `
                <div data-gjs-slugLofty="itemListEndpointDatabase" data-gjs-name="Item List Read" style="padding: 10px;" >
                  <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="customListString" data-gjs-name="Texto Servicio Personalizado" data-gjs-draggable="false">
                    {{ Texto }}
                  </div>
                </div>`;
                  this.model.append(componentCustom);
                } else if (endpoint.data?.responseType === 'number') {
                  const componentCustom = `
                <div data-gjs-slugLofty="itemListEndpointDatabase" data-gjs-name="Item List Read" style="padding: 10px;" >
                  <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="customListNumber" data-gjs-name="Numero Servicio Personalizado" data-gjs-draggable="false">
                    {{ Numero }}
                  </div>
                </div>`;
                  this.model.append(componentCustom);
                } else {
                  const componentCustom = `
                <div data-gjs-slugLofty="itemListEndpointDatabase" data-gjs-name="Item List Read" style="padding: 10px;" >
                  <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="customListBoolean" data-gjs-name="Booleano Servicio Personalizado" data-gjs-draggable="false">
                    {{ Booleano }}
                  </div>
                </div>`;
                  this.model.append(componentCustom);
                }
                const getModelData = this.model.get('components');
                const formModelData = getModelData.filter((index) => (index.attributes.sluglofty === 'itemListEndpointDatabase'));
                const functionSetter = formModelData[0].attributes.components.models;
                functionSetter.forEach((index) => index.set({draggable: (e, d) => validDestination(e, d, 'itemListEndpointDatabase')}));
                if ((findParams || hasVariables || findReadDBParent) && getParamData.data.length > 0) {
                  captureDataForCustomEndpointCall(this.model, findParams, hasVariables, findReadDBParent);
                  // 'string' | 'number' | 'boolean'
                }
              }
              // console.log(this.model);
              const getModelData = this.model.get('components');
              getModelData.forEach((index) => index.set({draggable: (e, d) => validDestination(e, d, 'ReadDBContainer')}));
              // console.log(this.model);
            } catch (error) {
              // console.log(error);
            }
          }
        } else if (this.model.attributes.sluglofty.includes('AddDBContainer')) {
          try {
            const token = opts.token;
            const idProject = opts.idProject;
            const idPage = opts.idPage;
            let traitValue = '';
            if (this.model.getTrait('dbSelect').attributes.value !== '') {
              traitValue = this.model.getTrait('dbSelect').attributes.value;
            } else {
              traitValue = this.model.attributes.dbSelect;
            }
            // GET PAGE INFO
            const pageInfoData = await getOnePageApiPages({token, _id: idPage});
            let flagHasUserField = false;
            const endpointSelection = this.model.getTrait('endpointSelect');
            const userFieldComponentData = [];
            const endpointValue = endpointSelection.attributes.value;
            // const getOption = this.model.getTrait('endpointSelect').attributes.options;
            // const filterData = getOption.filter((obj) => (obj.value === endpointValue));
            // const filterNameEndpoint = filterData[0].name;
            const infoEndpoint = await getOneEndpoint({token, _id: endpointValue});
            const filterNameEndpoint = infoEndpoint.data.typeFunction;
            const idDatabase = traitValue;
            const fieldList = await listFieldsDatabase({token, idDatabase, idProject});
            const listRelations = [];
            const relationData = [];
            const typeDB = await getOneDatabase({token, _id: idDatabase});
            fieldList.data.map((index) => {
              if (index.type === 'relation') {
                listRelations.push(index.relationDatabase);
                relationData.push(index);
              }
            });
            const userInfoData = await listSimulatorUserProject({token, idProject});
            const dataCaptureInfo = await getMainData(listRelations, token, idProject);

            if (filterNameEndpoint === 'customCreate') {
              const getParamData = await listParamsEndpoint({
                idEndpoint: endpointValue,
                idProject,
                token,
              });
              const listCustomRelations = [];
              const relationCustomData = [];
              getParamData.data.forEach((index) => {
                if (index.type === 'relation') {
                  listCustomRelations.push(index.relationDatabase);
                  relationCustomData.push(index);
                }
              });
              const dataCustomCapture = await getMainData(listCustomRelations, token, idProject);
              if (getParamData.data.length > 0) {
                let htmlAdder = '<form data-gjs-resizable="{bc: 1}" style="padding: 10px;" data-gjs-slugLofty="formAddDatabase" data-gjs-name="Form Add Database" title="addFormDatabase">';
                const postFields = databaseFieldsPostComponent(getParamData.data, [], dataCustomCapture);

                htmlAdder += `
                  ${postFields}
                  <button
                    type="submit"
                    data-gjs-slugLofty="formAddDatabaseButton"
                    data-gjs-name="Boton Agregar a Coleccion de Datos"
                    data-gjs-resizable="{bc: 1}"
                  >
                    <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Submit</div>
                  </button>
                </form>`;
                this.model.append(htmlAdder);
              }
            } else {
              let htmlAdder = '<form data-gjs-resizable="{bc: 1}" style="padding: 10px;" data-gjs-slugLofty="formAddDatabase" data-gjs-name="Form Add Database" title="addFormDatabase">';
              // eslint-disable-next-line array-callback-return
              fieldList.data.map((index) => {
              // 'string' | 'number' | 'boolean' | 'date' | 'relation' | 'user';
                if (index.type === 'string') {
                  htmlAdder += `
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="fieldDatabaseAddContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=addFormDatabase]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <label data-gjs-slugLofty="formLabelStringDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
                    <input data-gjs-resizable="{bc: 1}" type="text" data-gjs-slugLofty="formInputDatabase" data-gjs-name="${index.name} Texto" style="background-color: transparent" placeholder="${index.label}" name="${index.name}" `;
                  if (index.required) {
                    htmlAdder += 'required';
                  }
                  htmlAdder += `/>
                  </div>
                `;
                } else if (index.type === 'date') {
                  htmlAdder += `
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="fieldDatabaseAddContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=addFormDatabase]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <label data-gjs-slugLofty="formLabelDateDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
                    <input data-gjs-resizable="{bc: 1}" type="date" data-gjs-slugLofty="formDateInputDatabase" data-gjs-name="${index.name} Fecha" style="background-color: transparent" value="2018-07-22" name="${index.name}"`;
                  if (index.required) {
                    htmlAdder += 'required';
                  }
                  htmlAdder += `/>
                  </div>
                `;
                } else if (index.type === 'number') {
                  htmlAdder += `
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="fieldDatabaseAddContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=addFormDatabase]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                  <label data-gjs-slugLofty="formLabelNumberDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
                    <input data-gjs-resizable="{bc: 1}" type="number" data-gjs-slugLofty="formNumberInputDatabase" data-gjs-name="${index.name} Numero" style="background-color: transparent" placeholder="${index.label}"  name="${index.name}"`;
                  if (index.required) {
                    htmlAdder += 'required';
                  }
                  htmlAdder += `/>
                  </div>
                `;
                } else if (index.type === 'boolean') {
                  htmlAdder += `
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="fieldDatabaseAddContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=addFormDatabase]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <label data-gjs-slugLofty="formLabelBooleanDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
                    <input type="checkbox" data-gjs-slugLofty="formBooleanInputDatabase" style="background-color: transparent" data-gjs-name="${index.name} Casilla" name="${index.name}"`;
                  if (index.required) {
                    htmlAdder += 'required';
                  }
                  htmlAdder += `/>
                    <label data-gjs-slugLofty="formLabelCheckbox" data-gjs-name="${index.label} Booleano"> ${index.name} </label>
                  </div>
                `;
                } else if (index.type === 'relation') {
                  const relationIDSeeker = index.relationDatabase;
                  // console.log(dataCaptureInfo[relationIDSeeker].data);
                  const selectionData = dataCaptureInfo[relationIDSeeker].data;
                  const listFieldsForeignDatabase = dataCaptureInfo[relationIDSeeker].fields;
                  const doc = {};
                  doc[index.name] = {};
                  doc[index.name].data = selectionData;
                  doc[index.name].fields = listFieldsForeignDatabase;
                  htmlAdder += `
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="fieldRelationDatabaseAddContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=addFormDatabase]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <label data-gjs-slugLofty="formLabelSelectRelationDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
                    <select data-gjs-slugLofty="formSelectRelation" data-gjs-name="${index.name} Select" name="${index.name}" style="background-color: transparent" data-gjs-id="${index._id}"
                `;
                  if (index.required) {
                    htmlAdder += 'required';
                  }
                  htmlAdder += '>';
                  // ADD OPTIONS
                  doc[index.name]?.data?.map((keyInfo) => {
                    let label = '';
                    const fields = doc[index.name].fields;
                    const tempData = keyInfo.data;
                    fields.map((field) => {
                      if (field.isLabel) {
                        label += `${tempData[field.name]}`;
                      }
                    });
                    htmlAdder += `<option data-gjs-slugLofty="formSelectRelationOption" data-gjs-name="${index.name} Opcion" value="${keyInfo._id}">${label}</option>`;
                  });
                  htmlAdder += `
                    </select>
                  </div>
                `;
                } else if (index.type === 'user') {
                  flagHasUserField = true;
                  userFieldComponentData.push(index);
                  const resUserData = userInfoData.data;
                  htmlAdder += `
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="fieldUserDatabaseAddContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=addFormDatabase]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <label data-gjs-slugLofty="formLabelUserDatabase" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
                    <select data-gjs-slugLofty="formSelectUser" data-gjs-name="${index.name} Seleccion" style="background-color: transparent" name="${index.name}" data-gjs-id="${index._id}"
                `;
                  if (index.required) {
                    htmlAdder += 'required';
                  }
                  htmlAdder += '>';
                  resUserData?.map((index) => {
                    htmlAdder += `
                    <option data-gjs-slugLofty="formSelectUserOption" data-gjs-name="${index.name} Opcion" value="${index._id}">${index.data.loftyEmail}</option>
                  `;
                  });
                  htmlAdder += `
                    </select>
                  </div>
                `;
                } else if (index.type === 'textrich') {
                  htmlAdder += `
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="fieldRichTextDatabaseAddContainer"
                    data-gjs-valuelofty="${index.name}"
                    data-gjs-name="${index.label}"
                    data-gjs-draggable="[title=addFormDatabase]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <label data-gjs-slugLofty="formLabelRichTextDatabaseS" data-gjs-name="Etiqueta ${index.name}">${index.label}</label><br/>
                    <textarea data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="formRichTextDatabase" data-gjs-name="${index.name} Texto Enriquecido" style="background-color: transparent" placeholder="${index.label}" name="${index.name}" `;
                  if (index.required) {
                    htmlAdder += 'required';
                  }
                  htmlAdder += `></textarea>
                  </div>
                `;
                }
              });
              if (typeDB.data.isBucket) {
                htmlAdder += `
                  <div
                    data-gjs-resizable="{bc: 1}"
                    data-gjs-slugLofty="fieldDatabaseBucketAddContainer"
                    data-gjs-valuelofty="fieldUploadFiletoBucket"
                    data-gjs-name="Archivo a subir a Almacenador de Archivos"
                    data-gjs-draggable="[title=addFormDatabase]"
                    style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                  >
                    <label data-gjs-slugLofty="formLabelBucketDatabase" data-gjs-name="Etiqueta Subir Archivo">Upload File</label><br/>
                    <input type="file" data-gjs-slugLofty="formBucketFileDatabase" data-gjs-name="Entrada para Subir Archivo" style="background-color: transparent" name="fileBucket" />
                  </div>
                `;
              }
              htmlAdder += `
              <button
                type="submit"
                data-gjs-slugLofty="formAddDatabaseButton"
                data-gjs-name="Boton Agregar a Coleccion de Datos"
                data-gjs-resizable="{bc: 1}"
              >
                <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Submit</div>
              </button>
            </form>`;
              this.model.append(htmlAdder);
            }

            const traitAlert = {
              type: 'select',
              name: 'alertTrait',
              label: 'Accion Exitosa',
              changeProp: 1,
              options: [{value: 'alert', name: 'Alerta'}, {value: 'function', name: 'funcion'}, {value: 'null', name: 'N/A'}],
              default: 'null',
            };
            this.model.addTrait(traitAlert);
            //
            if (flagHasUserField && (pageInfoData.data.authProtocol === 'active' || pageInfoData.data.authProtocol === 'inactive')) {
              const traitUseLoggedUser = {
                type: 'select',
                name: 'useCurrentUserAddDatabase',
                label: 'Usar usuario activo',
                changeProp: 1,
                options: [{value: 'nouser', name: 'No'}, {value: 'usecurrent', name: 'Usar Usuario Activo'}],
                default: 'nouser',
              };
              this.model.addTrait(traitUseLoggedUser);
              this.model.addAttributes({userfieldInfo: userFieldComponentData});
            }
            const getModelData = this.model.get('components');
            // getModelData.forEach((index) => index.set({draggable: (e, d) => validDestination(e, d, 'formAddDatabase')}));
            const formModelData = getModelData.filter((index) => (index.attributes.sluglofty === 'formAddDatabase'));
            const functionSetter = formModelData[0].attributes.components.models;
            functionSetter.forEach((index) => index.set({draggable: (e, d) => validDestination(e, d, 'formAddDatabase')}));
            // VALIDACION
            const resPage = await getOnePageApiPages({token, _id: idPage});
            const urlParamsRaw = resPage.data.url.split('/');
            let hasParams = false;
            const filterParams = [];
            urlParamsRaw.forEach((index) => {
              if (index.includes(':')) {
                hasParams = true;
                const temp = index.split(':');
                const paramData = temp[1];
                filterParams.push({value: paramData, name: paramData});
              }
            });
            if (hasParams && relationData.length > 0) {
              //
              const relationTraitInfo = [];
              relationData.forEach((index) => {
                relationTraitInfo.push({relationInput: index, state: 'input'});
              });
              const traitUseParamForRelation = {
                type: 'button',
                text: 'Manejar Inputs de Relaciones',
                name: 'buttonRelationInputManager',
                full: true,
                command: () => handleButtonClickOpenManager(filterParams, this.model),
              };
              this.model.addTrait(traitUseParamForRelation);
              this.model.addAttributes({relationInputs: relationTraitInfo});
            }
          } catch (error) {
            // console.log(error);
          }
        } else if (this.model.attributes.sluglofty.includes('Delete')) {
          try {
            // const filterData = [];
            const idPage = opts.idPage;
            const token = opts.token;
            /**
                 * SEARCH FOR FILTER TYPES
                 *
                 * If Has : then params exists
                 * If has variables (local/global) then can use vars
                 * If Parent Component is ReadDB use parentComponent
                 */

            const findParams = await hasParamsInPage(token, idPage);

            // Search for Variables
            let hasVariables = false;
            const localVars = opts.localVars;
            const globalVars = opts.globalVars;
            if (localVars.length > 0 || globalVars.length > 0) {
              hasVariables = true;
            }

            // Search for Parent Component
            const findReadDBParent = searchForReadDB(this.model);

            // if has value
            const traitTypeFilterValue = this.model.attributes.typeOfFilterManager || '';

            if (findParams || findReadDBParent || hasVariables) {
              const typeOfFilterOptions = [];
              if (findParams) {
                typeOfFilterOptions.push({name: 'Parametros', value: 'params'});
              }
              if (findReadDBParent) {
                typeOfFilterOptions.push({name: 'Por Componente de Lectura', value: 'readcomponent'});
              }
              if (hasVariables) {
                typeOfFilterOptions.push({name: 'Por Variables', value: 'variables'});
              }
              const traitTypeFilter = {
                type: 'select',
                name: 'typeOfFilterManager',
                label: 'Tipo de filtro',
                changeProp: 1,
                options: typeOfFilterOptions,
                default: traitTypeFilterValue,
              };
              this.model.addTrait(traitTypeFilter);
              // const traitDeletion = {
              //   type: 'select',
              //   name: 'filterDelete',
              //   label: 'Filter',
              //   changeProp: 1,
              //   options: filterData,
              //   default: '',
              // };
              // this.model.addTrait(traitDeletion);
              const htmlAdder = `
                <button 
                  data-gjs-slugLofty="deletionButton"
                  data-gjs-name="Eliminar/Remover"
                  data-gjs-draggable="[title=crudContainer]"
                  data-gjs-resizable="{bc: 1}"
                >
                  <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Delete</div>
                </button>
              `;
              this.model.append(htmlAdder);
            }
          } catch (error) {
            // console.log(error);
          }
        } else if (this.model.attributes.sluglofty.includes('Update')) {
          try {
            const filterData = [];
            const idPage = opts.idPage;
            const idProject = opts.idProject;
            let traitValue = '';
            if (this.model.getTrait('dbSelect').attributes.value !== '') {
              traitValue = this.model.getTrait('dbSelect').attributes.value;
            } else {
              traitValue = this.model.attributes.dbSelect;
            }
            const idDatabase = traitValue;
            const token = opts.token;
            const pageInfoData = await getOnePageApiPages({token, _id: idPage});
            let flagHasUserField = false;
            const userFieldComponentData = [];
            const fieldList = await listFieldsDatabase({token, idDatabase, idProject});
            const listRelations = [];
            const relationData = [];
            const typeDB = await getOneDatabase({token, _id: idDatabase});
            fieldList.data.map((index) => {
              if (index.type === 'relation') {
                listRelations.push(index.relationDatabase);
                relationData.push(index);
              }
            });
            /**
                 * SEARCH FOR FILTER TYPES
                 *
                 * If Has : then params exists
                 * If has variables (local/global) then can use vars
                 * If Parent Component is ReadDB use parentComponent
                 */

            const findParams = await hasParamsInPage(token, idPage);

            // Search for Variables
            let hasVariables = false;
            const localVars = opts.localVars;
            const globalVars = opts.globalVars;
            if (localVars.length > 0 || globalVars.length > 0) {
              hasVariables = true;
            }

            // Search for Parent Component
            const findReadDBParent = searchForReadDB(this.model);

            // if has value
            const traitTypeFilterValue = this.model.attributes.typeOfFilterManager || '';
            const userInfoData = await listSimulatorUserProject({token, idProject});
            const dataCaptureInfo = await getMainData(listRelations, token, idProject);

            const endpointSelection = this.model.getTrait('endpointSelect');
            const endpointValue = endpointSelection.attributes.value;
            // const getOption = this.model.getTrait('endpointSelect').attributes.options;
            // const filterOptions = getOption.filter((obj) => (obj.value === endpointValue));
            // const filterNameEndpoint = filterOptions[0].name;
            const infoEndpoint = await getOneEndpoint({token, _id: endpointValue});
            const filterNameEndpoint = infoEndpoint.data.typeFunction;
            if (filterNameEndpoint === 'customUpdate') {
              const getParamData = await listParamsEndpoint({
                idEndpoint: endpointValue,
                idProject,
                token,
              });
              const listCustomRelations = [];
              const relationCustomData = [];
              const filterDataForForm = getParamData.data.filter((index) => (index.typeParam === 'body'));
              const filterDataForParams = getParamData.data.filter((index) => (index.typeParam === 'query'));
              filterDataForForm.forEach((index) => {
                if (index.type === 'relation') {
                  listCustomRelations.push(index.relationDatabase);
                  relationCustomData.push(index);
                }
              });
              if (filterDataForParams.length > 0) {
                paramValues = filterDataForParams.map((index) => ({
                  paramendpoint: {
                    value: index.name,
                    label: index.label,
                    type: index.type,
                    stringDefaultValues: index?.stringDefaultValues || [],
                  },
                  state: index?.stringDefaultValues?.length > 0 ? 'static' : 'useparams',
                }));
                this.model.addAttributes({customParams: paramValues});
              } else {
                this.model.addAttributes({withoutParams: 'noparamsinendpoint'});
              }
              const dataCustomCapture = await getMainData(listCustomRelations, token, idProject);
              if (filterDataForForm.length > 0) {
                let htmlAdder = '<form data-gjs-resizable="{bc: 1}" style="padding: 10px;" data-gjs-slugLofty="formUpdateDatabase" data-gjs-name="Actualizar Datos" title="updateFormDatabase">';
                const updateFields = databaseFieldsUpdateComponent(filterDataForForm, userInfoData, dataCustomCapture);

                htmlAdder += `
                  ${updateFields}
                  <button
                    type="submit"
                    data-gjs-slugLofty="formUpdateDatabaseButton"
                    data-gjs-name="Boton Actualizar a Coleccion de Datos"
                    data-gjs-resizable="{bc: 1}"
                  >
                    <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Submit</div>
                  </button>
                </form>`;
                this.model.append(htmlAdder);
                if ((findParams || hasVariables || findReadDBParent) && filterDataForParams.length > 0) {
                  captureDataForCustomEndpointCall(this.model, findParams, hasVariables, findReadDBParent);
                }
                const getModelData = this.model.get('components');
                const formModelData = getModelData.filter((index) => (index.attributes.sluglofty === 'formUpdateDatabase'));
                const functionSetter = formModelData[0].attributes.components.models;
                functionSetter.forEach((index) => index.set({draggable: (e, d) => validDestination(e, d, 'formUpdateDatabase')}));
                const traitAlert = {
                  type: 'select',
                  name: 'alertTrait',
                  label: 'Accion Exitosa',
                  changeProp: 1,
                  options: [{value: 'alert', name: 'Alerta'}, {value: 'function', name: 'funcion'}, {value: 'null', name: 'N/A'}],
                  default: 'null',
                };
                this.model.addTrait(traitAlert);
              }
            } else if (findParams || findReadDBParent || hasVariables) {
              const typeOfFilterOptions = [];
              if (findParams) {
                typeOfFilterOptions.push({name: 'Parametros', value: 'params'});
              }
              if (findReadDBParent) {
                typeOfFilterOptions.push({name: 'Por Componente de Lectura', value: 'readcomponent'});
              }
              if (hasVariables) {
                typeOfFilterOptions.push({name: 'Por Variables', value: 'variables'});
              }
              const traitTypeFilter = {
                type: 'select',
                name: 'typeOfFilterManager',
                label: 'Tipo de filtro',
                changeProp: 1,
                options: typeOfFilterOptions,
                default: traitTypeFilterValue,
              };
              this.model.addTrait(traitTypeFilter);
              const findUser = fieldList.data.find((index) => (index.type === 'user'));
              if (findUser) {
                flagHasUserField = true;
              }
              let htmlAdder = '<form data-gjs-resizable="{bc: 1}" style="padding: 10px;" data-gjs-slugLofty="formUpdateDatabase" data-gjs-name="Formulario Actualizar Coleccion de Datos" title="updateFormDatabase">';
              const updateFields = databaseFieldsUpdateComponent(fieldList.data, userInfoData, dataCaptureInfo);
              htmlAdder += `${updateFields}`;
              if (typeDB.data.isBucket) {
                htmlAdder += `
                      <div
                        data-gjs-slugLofty="fieldDatabaseBucketUpdateContainer"
                        data-gjs-valuelofty="fieldUploadFiletoBucket"
                        data-gjs-name="Archivo a actualizar en Almacenador de Archivos"
                        data-gjs-draggable="[title=updateFormDatabase]"
                        style="padding: 5px; margin-top: 5px; margin-bottom: 5px"
                        data-gjs-resizable="{bc: 1}"
                      >
                        <label data-gjs-slugLofty="formUpdateLabelBucketDatabase" data-gjs-name="Etiqueta Actualizar Archivo">Upload File</label><br/>
                        <input type="file" data-gjs-slugLofty="formBucketFileDatabaseUpdate" style="background-color: transparent" data-gjs-name="Entrada Actualizar Archivo" name="fileBucket" />
                      </div>
                    `;
              }
              htmlAdder += `
                  <button
                    type="submit"
                    data-gjs-slugLofty="formUpdateDatabaseButton"
                    data-gjs-name="Boton Actualizar en Coleccion de Datos"
                    data-gjs-draggable="[title=updateFormDatabase]"
                    data-gjs-resizable="{bc: 1}"
                  >
                    <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton ">Submit</div>
                  </button>
                </form>`;
              this.model.append(htmlAdder);
              const getModelData = this.model.get('components');
              const formModelData = getModelData.filter((index) => (index.attributes.sluglofty === 'formUpdateDatabase'));
              const functionSetter = formModelData[0].attributes.components.models;
              functionSetter.forEach((index) => index.set({draggable: (e, d) => validDestination(e, d, 'formUpdateDatabase')}));
              // getModelData.forEach((index) => index.set({draggable: (e, d) => validDestination(e, d, 'formUpdateDatabase')}));
              // console.log(this.model);
              const traitAlert = {
                type: 'select',
                name: 'alertTrait',
                label: 'Accion Exitosa',
                changeProp: 1,
                options: [{value: 'alert', name: 'Alerta'}, {value: 'function', name: 'funcion'}, {value: 'null', name: 'N/A'}],
                default: 'null',
              };
              this.model.addTrait(traitAlert);
              if (flagHasUserField && (pageInfoData.data.authProtocol === 'active' || pageInfoData.data.authProtocol === 'inactive')) {
                const traitUseLoggedUser = {
                  type: 'select',
                  name: 'useCurrentUserAddDatabase',
                  label: 'Usar usuario activo',
                  changeProp: 1,
                  options: [{value: 'nouser', name: 'No'}, {value: 'usecurrent', name: 'Usar Usuario Activo'}],
                  default: 'nouser',
                };
                this.model.addTrait(traitUseLoggedUser);
                this.model.addAttributes({userfieldInfo: userFieldComponentData});
              }
              if (hasParams && relationData.length > 0) {
                const filterParams = filterData;
                const relationTraitInfo = [];
                relationData.forEach((index) => {
                  relationTraitInfo.push({relationInput: index, state: 'input'});
                });
                const traitUseParamForRelation = {
                  type: 'button',
                  text: 'Manejar Inputs de Relaciones',
                  name: 'buttonRelationInputManager',
                  full: true,
                  command: () => handleButtonClickOpenManager(filterParams, this.model),
                };
                this.model.addTrait(traitUseParamForRelation);
                this.model.addAttributes({relationInputs: relationTraitInfo});
              }
            }
          } catch (error) {
            // console.log(error);
          }
        }
      },
      async alertAdder() {
        try {
          if (this.model.attributes.sluglofty.includes('AddDBContainer') || this.model.attributes.sluglofty.includes('Update')) {
            const alertSelection = this.model.getTrait('alertTrait').attributes.value;
            this.model.removeTrait('customFunctionPlugin');
            this.model.removeTrait('alertMessage');
            if (alertSelection === 'alert') {
              const alertMessage = {
                type: 'text',
                name: 'alertMessage',
                label: 'Mensaje',
                changeProp: 1,
              };
              this.model.addTrait(alertMessage);
            } else if (alertSelection === 'function') {
              const seekFunctions = await getFunctionPagesData();
              const values = [];
              if (seekFunctions.length > 0) {
                seekFunctions.forEach((index) => {
                  values.push({value: index._id, name: index.label, content: index});
                });
                const traitAddition = {
                  type: 'select',
                  name: 'customFunctionPlugin',
                  label: 'Funcion',
                  changeProp: 1,
                  options: values,
                };
                this.model.addTrait(traitAddition);
              }
            }
          }
        } catch (error) {
          //
        }
      },
      async selectionAddParamRelation() {
        try {
          if (this.model.attributes.sluglofty.includes('fieldRelationDatabaseAddContainer')) {
            const paramSelection = this.model.getTrait('useParamToAddWithParamRelation').attributes.value;
            if (paramSelection === 'useparams') {
              const filterData = [];
              const idPage = opts.idPage;
              const token = opts.token;
              const resPage = await getOnePageApiPages({token, _id: idPage});
              const urlParamsRaw = resPage.data.url.split('/');
              urlParamsRaw.forEach((index) => {
                if (index.includes(':')) {
                  const temp = index.split(':');
                  const paramData = temp[1];
                  filterData.push({value: paramData, name: paramData});
                }
              });
              const traitRead = {
                type: 'select',
                name: 'filterAdd',
                label: 'Filtro',
                changeProp: 1,
                options: filterData,
                default: '',
              };
              this.model.addTrait(traitRead);
            } else {
              this.model.removeTrait('filterAdd');
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
      async selectTypeOfReadManager() {
        try {
          // GENERAL FILTER
          const traitValue = this.model.attributes.typeOfFilterManager;
          let filterOptionsData = [];
          const idPage = opts.idPage;
          const token = opts.token;
          const idProject = opts.idProject;
          if (traitValue === 'params') {
            filterOptionsData = await getAllPageParams(token, idPage);
          } else if (traitValue === 'readcomponent') {
            const findReadDBParent = searchForReadDB(this.model);
            filterOptionsData = await getAllReadRelationFieldsForParams(findReadDBParent, token, idProject);
            filterOptionsData.push({value: '_id', name: 'Identificador'});
          } else if (traitValue === 'variables') {
            const varData = [];
            const localVars = opts.localVars;
            const globalVars = opts.globalVars;
            localVars.filter((index) => (index.type === 'string'))
              .forEach((variable) => {
                varData.push({name: `${variable.label}--local`, value: variable._id});
              });
            globalVars.filter((index) => (index.typeValue === 'string'))
              .forEach((variable) => {
                varData.push({name: `${variable.label}--global`, value: variable._id});
              });
            filterOptionsData = varData;
          }
          if (this.model.attributes.sluglofty === ('ReadDBContainer')) {
            this.model.removeTrait('filterAdd');
            const hasValue = this.model.attributes.filterAdd || '';
            const traitFilterManager = {
              type: 'select',
              name: 'filterAdd',
              label: 'Filtro',
              changeProp: 1,
              options: filterOptionsData,
              default: hasValue,
            };
            this.model.addTrait(traitFilterManager);
          } else if (this.model.attributes.sluglofty === ('UpdateDBContainer')) {
            this.model.removeTrait('filterUpdate');
            const hasValue = this.model.attributes.filterUpdate || '';
            const traitFilterManager = {
              type: 'select',
              name: 'filterUpdate',
              label: 'Filtro',
              changeProp: 1,
              options: filterOptionsData,
              default: hasValue,
            };
            this.model.addTrait(traitFilterManager);
          } else if (this.model.attributes.sluglofty === 'DeleteDBContainer') {
            this.model.removeTrait('filterDelete');
            const hasValue = this.model.attributes.filterDelete || '';
            const traitFilterManager = {
              type: 'select',
              name: 'filterDelete',
              label: 'Filtro',
              changeProp: 1,
              options: filterOptionsData,
              default: hasValue,
            };
            this.model.addTrait(traitFilterManager);
          }
        } catch (error) {
          // console.log(error);
        }
      },
      handleEditorInputManager() {

      },
      selectCustomFunction() {
        try {
          if (this.model.attributes.sluglofty === ('AddDBContainer') || this.model.attributes.sluglofty === ('UpdateDBContainer')) {
            const functionSelection = this.model.getTrait('customFunctionPlugin');
            const availableOptions = functionSelection.attributes.options;
            const selectedValue = functionSelection.attributes.value;
            const getSelectedOption = availableOptions.find((index) => (
              index.value === selectedValue
            ));
            this.model.addAttributes({customPageFunction: getSelectedOption.content});
          }
        } catch (error) {
          //
        }
      },
    }),
  });
};
