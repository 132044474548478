import React, {useState, useEffect} from 'react';
import geditorConfig from '../editorPdfConfig/config';
import Sidebar from './EditorNormalComponent.js/Sidebar';
import TopNav from './EditorNormalComponent.js/TopNav';
// import LogoImg from '../assets/navbar/logo_sidebar.png';

function EditorPdf({
  idProject, token, idPluginProject, url, _id, handleOpenDrawer,
}) {
  // eslint-disable-next-line no-unused-vars
  const [editor, setEditor] = useState(null);
  useEffect(() => {
    const editorNew = geditorConfig({
      url,
      _id,
      token,
      idProject,
      idPluginProject,
      handleOpenDrawer,
    });
    setEditor(editorNew);
  }, []);

  return (
    <div className="App">
      <div id="navbar" className="sidenav d-flex flex-column overflow-scroll">
        <nav className="navbar navbar-light">
          <div className="container-fluid" style={{display: 'flex', justifyContent: 'center'}} id="initial-Section-editor-lofty">
            {/*
              <img src={LogoImg} alt="Lofty Apps" width="200px" height="75px" />
            */}
          </div>
        </nav>
        <Sidebar />
      </div>
      <div className="main-content" id="main-content">
        <TopNav />
        <div id="editor" />
      </div>
    </div>
  );
}

export default EditorPdf;
