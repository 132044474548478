import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Typography from '@material-ui/core/Typography';
import {Box, IconButton} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles(() => ({
  mainBox: {
    margin: '15px',
  },
  root: {
    width: '100%',
    // border: '1px solid',
    // borderColor: 'rgba(47, 115, 234, 1)',
  },
  boxItemMain: {
    display: 'flex',
    flexDirection: 'row',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    padding: '10px',
    width: '30%',
    // color: '#144999',
    color: '#fff',
    overflowX: 'hidden',
  },
  typeContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    padding: '10px',
    width: '25%',
    color: '#fff',
    // color: '#144999',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    padding: '10px',
    width: '30%',
    overflowX: 'hidden',
    // color: '#144999',
    color: '#fff',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignContent: 'center',
    alignItems: 'center',
    padding: '10px',
    width: '15%',
  },
  itemsFontWeight: {
    fontWeight: 600,
  },
  buttonStyle: {
    // background: 'gray',
    // color: '#292151',
    color: '#fff',
  },
  divisorVariables: {
    background: '#F3F3F3',
    height: '1px',
    width: '100%',
  },
}));

export default function ListVariables({
  name, label, type, deleteVariable, idVariable, variableType,
}) {
  const classes = useStyles();
  function translateType(type) {
    switch (type) {
      case 'number':
        return 'Numero';
      case 'boolean':
        return 'Booleano';
      case 'string':
        return 'Texto';
      case 'arrayDatabase':
        return 'Arreglo';
      case 'database':
        return 'Dato';
      case 'cartArray':
        return 'Carrito de Compras';
      case 'date':
        return 'Fecha';
      case 'user':
        return 'Usuario';
      default:
        return 'N/A';
    }
  }
  return (
    <Box component="div" className={classes.mainBox}>
      <Box className={classes.root}>
        <Box className={classes.boxItemMain}>
          <Box component="div" className={classes.details}>
            <Box className={classes.nameContainer}>
              <Typography className={classes.itemsFontWeight}>
                {name}
              </Typography>
            </Box>
            <Box className={classes.labelContainer}>
              <Typography className={classes.itemsFontWeight}>
                {label}
              </Typography>
            </Box>
            <Box className={classes.typeContainer}>
              <Typography className={classes.itemsFontWeight}>
                {translateType(type)}
              </Typography>
            </Box>
            <Box className={classes.optionsContainer}>
              <HtmlTooltip
                title={(
                  <>
                    <Typography color="inherit">Eliminacion de variable</Typography>
                    {/* <em>Editor para creacion y personalizacion de paginas del proyecto</em> */}
                  </>
              )}
              >
                <IconButton
                  onClick={() => deleteVariable({type: variableType, idVariable})}
                  className={classes.buttonStyle}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </HtmlTooltip>
            </Box>
          </Box>
          {/* */}
        </Box>
        <Box className={classes.divisorVariables} />
      </Box>
    </Box>
  );
}
