/* eslint-disable import/no-anonymous-default-export */
export default (editor, opts = {}) => {
  const bm = editor.BlockManager;
  const style = `<style>
  </style>
  `;
  bm.add(opts.name, {
    label: `
    <div style="display: flex; justify-content: center" >
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" class="icon-lofty-component-block" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="40px" height="40px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
 <g>
   <path d="M14.566,276.325h175.871c3.14,0,5.656-2.535,5.656-5.655V155.568c0-3.141-2.516-5.676-5.656-5.676
     H14.566c-3.121,0-5.656,2.536-5.656,5.676V270.67C8.911,273.79,11.446,276.325,14.566,276.325z M20.222,161.222h164.559v103.771
     H20.222V161.222z"/>
   <path d="M210.375,276.325h175.87c3.121,0,5.656-2.535,5.656-5.655V155.568c0-3.141-2.535-5.676-5.656-5.676
     h-175.87c-3.121,0-5.656,2.536-5.656,5.676V270.67C204.718,273.79,207.254,276.325,210.375,276.325z M216.03,161.222h164.56
     v103.771H216.03V161.222z"/>
   <path d="M406.338,276.325H582.21c3.121,0,5.655-2.535,5.655-5.655V155.568c0-3.141-2.534-5.676-5.655-5.676
     H406.338c-3.119,0-5.655,2.536-5.655,5.676V270.67C400.683,273.79,403.219,276.325,406.338,276.325z M411.994,161.222h164.56
     v103.771h-164.56V161.222z"/>
 </g>
 <g>
   <path d="M233.473,349.173c-2.975-2.95-7.778-2.95-10.701,0l-42.864,42.861
     c-2.975,2.948-2.975,7.726,0,10.703l42.864,42.862c2.923,2.95,7.726,2.95,10.701,0l0,0c2.977-2.978,2.977-7.78,0-10.729
     l-29.915-29.915h192.801c0.627,0,1.254-0.236,1.879-0.391l-30.331,30.306c-2.924,2.949-2.924,7.752,0,10.729l0,0
     c2.976,2.95,7.778,2.95,10.755,0l42.81-42.862c2.975-2.978,2.975-7.755,0-10.703l-42.81-42.861c-2.977-2.95-7.779-2.95-10.755,0
     c-2.924,2.976-2.924,7.753,0,10.727l30.331,30.281c-0.625-0.157-1.252-0.391-1.879-0.391H203.559l29.915-29.891
     C236.45,356.926,236.45,352.148,233.473,349.173z"/>
 </g>
 </svg>
    </div>
    <div class="gjs-block-label">
      ${opts.label}
    </div> 
    `,
    category: opts.category,
    attributes: {title: 'Muestra contenido secuencial, como imágenes o texto, en un área visible de tu página web.', id: 'component-single-dynamic-carousel-lofty-editor'},
    content: `
    <div
      data-gjs-sluglofty="singleDynamicCarouselComponent"
      style="padding: 10px;"
      data-gjs-resizable="{bc: 1}"
      data-gjs-name="Carrusel Dinamico"
       data-gjs-droppable="false"
    >
    </div>
  
    ${style}`,
  });
};
